import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {CommonModule} from '@angular/common';
import {NavigationBarComponent} from '../../core/components/navigation-bar/navigation-bar.component';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {preferred_lang} from "../../core/signals/translation.signal";

@Component({
    selector: 'app-terms',
    standalone: true,
    imports: [NgbModule, CommonModule, NavigationBarComponent, TranslateModule, RouterLink,],
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss'
})
export class TermsComponent implements OnInit {
    public readonly lang = preferred_lang;
    currentStage = 8;
    public isExternal: boolean = false;

    @Output() currentStageChange: EventEmitter<number> = new EventEmitter<number>();


    constructor(private router: Router, private route: ActivatedRoute) {
        this.route.url.pipe(takeUntilDestroyed()).subscribe(url => {
            this.isExternal = ['tos', 'gdpr'].includes(url[0]?.path);
        })
        this.route.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
            if (params["lang"]) {
                this.lang.set(params["lang"]);
            }
        })
    }


    ngOnInit(): void {
    }

    onCurrentStageChange(newStage: number) {
        this.currentStage = newStage; // Update the currentStage based on the navigation bar input
    }

    close() {
        this.router.navigate(['/', this.route.snapshot.queryParams?.['fromUrl'] ?? ""], {
            queryParams: {
                terms: true,
                currentStage: this.route.snapshot.queryParams?.['currentStage'] ?? 7
            }
        });
        this.currentStage = 7;
        this.currentStageChange.emit(this.currentStage);
    }

}
