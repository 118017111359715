import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RevolabButtonComponent} from "../../core/components/revolab-button/revolab-button.component";
import {RevolabPromoHeaderComponent} from '../../core/components/revolab-promo-header/revolab-promo-header.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {SessionStorageService} from "ngx-webstorage";
import {TranslateModule} from "@ngx-translate/core";
import {AccountMenuComponent} from "../../core/components/account-menu/account-menu.component";
import {LabService} from "../../core/services/lab.service";
import {preferred_lang} from "../../core/signals/translation.signal";
import {AppLocalStateService} from "../../core/services/app-local-state.service";
import {ModalComponent} from "../../core/components/modal/modal.component";
import {PendingActionsComponent} from "../pending-actions/pending-actions.component";

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [NgbModule, RevolabButtonComponent, RevolabButtonComponent, RevolabPromoHeaderComponent, CommonModule, RouterOutlet, NgOptimizedImage, TranslateModule, AccountMenuComponent, ModalComponent, PendingActionsComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly lang = preferred_lang;

    isMobile: boolean = false;
    isSticky: boolean = false;
    isVisible: boolean = false; // Banner starts as not visible

    private intersectionObserver: IntersectionObserver | undefined;
    private unsub$ = new Subject<void>();

    @ViewChild("section0") section0: ElementRef | undefined;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef,
        private session: SessionStorageService,
        private labService: LabService,
        private appLocal: AppLocalStateService,
    ) {
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkScreenSize();
    }

    ngOnInit(): void {
        // Check screen size on initialization
        this.checkScreenSize();
        this.labService.clearMapData()
        this.labService.clearStoredData();
        this.session.clear("appointment_summary");
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.intersectionObserver?.disconnect();
    }

    ngAfterViewInit() {
        // Check screen size on view ready
        this.checkScreenSize();
        this.route.queryParams.pipe(takeUntil(this.unsub$)).subscribe(query => {
            if (query['back']) {
                this.router.navigate([], {
                    queryParams: {
                        back: null
                    },
                    queryParamsHandling: 'merge'
                })
                this.labService.clearMapData();
                this.session.clear("appointment_summary");
                this.labService.clearStoredData();
            }
        });

        // Observe to make promo banner visible when main section is out of view
        if (!this.section0) {
            return;
        }

        this.intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this.isSticky = !entry.isIntersecting;
                this.isVisible = !entry.isIntersecting;
                this.cd.markForCheck();
            });
        }, {
            root: null, // observe relative to viewport
            rootMargin: '-30px',
            threshold: 0
        });

        this.intersectionObserver.observe(this.section0.nativeElement);
    }

    // Function to check if screen is mobile or desktop
    checkScreenSize() {
        if (typeof window !== "undefined") {
            const screenWidth = window.innerWidth;
            this.isMobile = screenWidth <= 768; // Adjust 768px as per your needs
        }
    }

    scrollToTop() {
        this.appLocal.mainElement()?.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
    }

}
