import {ChangeDetectorRef, Component, computed, inject, signal, WritableSignal} from '@angular/core';
import {MatCalendar, MatCalendarHeader, MatDatepickerIntl} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats} from '@angular/material/core';
import {startWith} from 'rxjs';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {preferred_lang} from "../../signals/translation.signal";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import "moment/locale/el";
import "moment/locale/en-gb";


@Component({
    selector: 'app-datepicker-modal',
    standalone: true,
    imports: [
        TitleCasePipe,
        MatButton,
        MatIconButton,
        DatePipe,
    ],
    templateUrl: './datepicker-modal.component.html',
    styleUrl: './datepicker-modal.component.scss'
})
export class DatepickerModalComponent<D> extends MatCalendarHeader<D> {
    protected readonly preferred_lang = preferred_lang;

    periodLabel: WritableSignal<Date | null> = signal(null);
    readonly dateFormat = computed(() => {
        switch (this._locale()) {
            case 'el':
                return 'EEEE, dd MMMM';
            default:
                return 'EEEE, MMMM dd';
        }
    })


    private readonly _adapter = inject<DateAdapter<D, unknown>>(DateAdapter);
    private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));

    constructor() {
        super(
            inject(MatDatepickerIntl),
            inject<MatCalendar<D>>(MatCalendar),
            inject<DateAdapter<D>>(DateAdapter),
            inject<MatDateFormats>(MAT_DATE_FORMATS),
            inject(ChangeDetectorRef)
        );
        this._locale.set(preferred_lang());
        this._adapter.setLocale(this._locale());
        this.calendar.stateChanges.pipe(startWith(null), takeUntilDestroyed()).subscribe(() => {
            this.periodLabel.set(this.calendar.activeDate as Date);
        });
    }
}
