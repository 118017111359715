<div class="d-flex h-100 flex-column gap-3 gap-md-5">
    <app-navigation-bar [currentStage]="-1" [showBackBtn]="false"></app-navigation-bar>
    <div class="container overflow-auto d-flex flex-column align-items-center flex-fill gap-md-3 gap-2">
        <div class="text-center">
            @if (bookingConf) {
                <i class="fa fa-check-circle mb-3 fa-6x text-diagnosou-accent"></i>
            }
            <h3 class="fw-bold">{{ message1 }}</h3>
            <p>{{ message2 }}</p>
        </div>
        <div class="position-relative w-100" [hidden]="bookingConf" [ngStyle]="{height: '25svh'}">
            <img ngSrc="/assets/images/Frame-1.png" fill
                 alt="sms confirmation" class="object-fit-contain">
        </div>
        @if (summary && summaryData) {
            <div class="flex-fill d-flex align-items-center">
                <div class="text-center rounded-2 shadow-sm border border-2 border-light py-3 px-5">
                    <span class="text-center text-diagnosou-accent">
                        @if (summaryData.lab?.name) {
                            <b>{{ summaryData.lab?.name }}</b><br>
                        }
                        @if (summaryData.lab?.address) {
                            {{ summaryData.lab?.address }}<br>
                        }
                    </span>
                    <span class="text-center d-block fs-6 mt-2">
                        @if (summaryData.date) {
                            <b>{{ summaryData.date || "" }}</b><br>
                        }
                        @if (summaryData.bookingNo) {
                            #{{ summaryData.bookingNo }}
                        }
                    </span>
                </div>
            </div>
        }
        @if (thanksMsg) {
            <h5 class="text-center text-diagnosou-accent fw-bold">
                {{ 'thank_you_for_using' | translate }}
                <p class="text-center my-2">
                    <img ngSrc="assets/images/Logo%20black.svg" alt="diagnosou" height="20" width="143" />
                </p>
            </h5>
        }
    </div>
    <div class="d-md-flex flex-md-row-reverse d-grid gap-2 m-md-5 m-3">
        <button (click)="okayClick()" class="btn btn-gradient">
            {{ (bookingConf ? 'appointment_completion_action_button_title' : 'generic_button_ok') | translate }}
        </button>
        <a [routerLink]="['/']" class="btn btn-link fw-bold text-dark">
            {{'appointment_completion_home_button_title' | translate }}
        </a>
    </div>

</div>
