import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output, viewChild,
} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Router} from '@angular/router';
import {ReactiveFormsModule} from "@angular/forms";
import {filter, Subject, takeUntil} from "rxjs";
import {map} from "rxjs/operators";
import {AccountMenuComponent} from "../account-menu/account-menu.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-navigation-bar',
    standalone: true,
    imports: [NgbModule, CommonModule, NgOptimizedImage, ReactiveFormsModule, AccountMenuComponent, TranslateModule],
    templateUrl: './navigation-bar.component.html',
    styleUrl: './navigation-bar.component.scss'
})
export class NavigationBarComponent implements OnInit, OnDestroy {

    @Input() currentStage: number = 0;
    @Input() hidden: boolean = false;
    @Input() showBackBtn: boolean = true;
    @Input() isRoot: boolean = false;

    @Output() currentStageChange: EventEmitter<number> = new EventEmitter<number>();

    searchInput = viewChild<ElementRef>('searchInput')

    public title: string = "";
    public subtitle: string = "";

    private lastRouteVisited: ActivatedRouteSnapshot | undefined;
    private unsub$ = new Subject<void>();

    constructor(
        private router: Router,
        public route: ActivatedRoute
    ) {

    }

    ngOnInit(): void {
        if (this.isRoot) {
            this.router.events
                .pipe(
                    takeUntil(this.unsub$),
                    filter(evt => evt instanceof ActivationEnd),
                    map(evt => evt as ActivationEnd)
                )
                .subscribe((data) => {
                    if (this.lastRouteVisited?.parent?.routeConfig?.path !== data.snapshot.routeConfig?.path) {
                        this.title = data.snapshot?.data?.['title'] ?? "";
                        this.subtitle = data.snapshot?.data?.['subtitle'] ?? "";
                        this.hidden = !data.snapshot?.data?.['showNav'];
                    }
                    this.lastRouteVisited = data.snapshot;
                })
        } else {
            this.route.data.pipe(takeUntil(this.unsub$)).subscribe(data => {
                this.title = data['title'] ?? "";
                this.subtitle = data['subtitle'] ?? "";
            })
        }
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.unsub$.complete();
    }

    goBack() {
        switch (this.currentStage) {
            // unknown
            case -1:
            // test selection
            case 10:
                this.router.navigate(['/'], {queryParams: {back: "true"}});
                break;
            // map
            case 0:
                this.router.navigate(['/test-select']);
                break;
            case 1:
                this.currentStage = 0;
                this.currentStageChange.emit(this.currentStage);
                break;
            case 2:
                this.currentStage = 1;
                this.currentStageChange.emit(this.currentStage);
                this.router.navigate(['/lab-select']);
                break;
            case 3:
                this.currentStage = 2;
                this.currentStageChange.emit(this.currentStage);
                break;
            case 4: //sms from idika
                this.currentStage = 0;
                this.currentStageChange.emit(this.currentStage);
                this.router.navigate(['/lab-select']);
                break;
            case 5: //criteria
                this.currentStage = 4;
                this.currentStageChange.emit(this.currentStage);
                break;
            case 6: //Costs cannot be covered
                this.currentStage = 5;
                this.currentStageChange.emit(this.currentStage);
                break;
            case 7: //Amka Check
                this.currentStage = 5;
                this.currentStageChange.emit(this.currentStage);
                break;
            case 8: //terms and conditions
                this.router.navigate(['/eligibility'], {queryParams: {currentStage: '7'}});
                this.currentStage = 7;
                this.currentStageChange.emit(this.currentStage);
                break;
            case 9: //appointment
                this.router.navigate(['/eligibility']);
                break;
            default:
                break;
        }
    }

}
