import {Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationBarComponent} from "../../core/components/navigation-bar/navigation-bar.component";
import {NgTemplateOutlet} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {preferred_lang} from "../../core/signals/translation.signal";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-gdpr',
  standalone: true,
    imports: [
        NavigationBarComponent,
        NgTemplateOutlet,
        TranslateModule
    ],
  templateUrl: './gdpr.component.html',
  styleUrl: './gdpr.component.scss'
})
export class GdprComponent {
    public readonly lang = preferred_lang;
    currentStage = 8;
    public isExternal: boolean = false;

    @Output() currentStageChange: EventEmitter<number> = new EventEmitter<number>();

    constructor(private router: Router, private route: ActivatedRoute) {
        this.route.url.pipe(takeUntilDestroyed()).subscribe(url => {
            this.isExternal = ['tos', 'gdpr'].includes(url[0]?.path);
        })
        this.route.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
            if (params["lang"]) {
                this.lang.set(params["lang"]);
            }
        });
    }

    onCurrentStageChange(newStage: number) {
        this.currentStage = newStage; // Update the currentStage based on the navigation bar input
    }

    close() {
        this.router.navigate(['/', this.route.snapshot.queryParams?.['fromUrl'] ?? ""], {
            queryParams: {
                terms: true,
                currentStage: this.route.snapshot.queryParams?.['currentStage'] ?? 7
            }
        });
        this.currentStage = 7;
        this.currentStageChange.emit(this.currentStage);
    }
}
