@if (!!appointment()) {

    <div class="appointment-card flex-column flex-md-row">
        <div
            class="d-flex status-container gap-2 align-items-center py-2 py-md-3 px-3 justify-content-between flex-md-column flex-row appointment-status-{{appointment()!.status | lowercase}}">
            <div class="text-center">
                <span class="fs-4 fw-bold d-block status-text">
                    {{ ('appointment_status_' + appointment()!.status) | lowercase | translate | titlecase }}
                </span>
                <small class="fw-bold opacity-75 d-none d-md-block">{{ appointment()!.createdAt | timeAgo:preferred_lang() }}</small>
            </div>
            <hr class="d-none m-0 w-100 d-md-block">
            <div class="d-md-none">
                <span class="fs-6 fw-bold">#{{ appointment()!.bookingNo }}</span>
            </div>
            <div class="d-none d-md-block text-center">
                <small class="opacity-75">{{ 'appointment_booking_date' | translate }}</small>
                <h4 class="m-0 fw-bold text-center">{{ appointment()!.date|date:'MMM dd':undefined:preferred_lang() }}</h4>
                <small class="opacity-75">{{ appointment()!.date|date:'yyyy':undefined:preferred_lang() }}</small>
            </div>
            <hr class="m-0 opacity-0 d-none d-md-block">

            <div class="d-none d-md-block text-center">
                <small class="opacity-75">{{ 'appointment_booking_time' | translate }}</small>
                <h4 class="m-0 fw-bold text-center">{{ appointment()!.slot }}</h4>
            </div>
        </div>
        <div class="d-flex flex-column w-100 justify-content-md-between">
            <div class="row px-md-0 py-md-3 mx-md-3 p-2 border-bottom">
                <div class="col-8 col-md-12 align-self-start align-items-md-center d-flex gap-2">
                    @if (!!appointment()!.lab.logo) {
                        <img [src]="appointment()!.lab.logo" height="40" class="border border-2 rounded-1"
                             alt="lab logo"/>
                    }
                    <span class="fs-6 fw-bold">{{ appointment()!.lab.displayName ?? appointment()!.lab.name }}</span>
                </div>
                <div class="col d-md-none text-center break-words">
                    <small class="text-secondary">{{ 'appointment_booking_date' | translate }}</small>
                    <h5 class="m-0 fw-bold">{{ appointment()!.date|date:'MMM dd':undefined:preferred_lang() }}</h5>
                    <small class="text-secondary">{{ appointment()!.date|date:'yyyy':undefined:preferred_lang() }}</small>
                </div>
            </div>
            <div class="row d-md-none p-2 border-bottom">
                <div class="col-8 d-flex justify-content-between gap-3">
                    <div>
                        <small class="text-secondary">{{ 'appointment_booking_creation_date' | translate }}</small>
                        <span class="fs-6 d-block">{{ appointment()!.createdAt| timeAgo:preferred_lang() }}</span>
                    </div>
<!--                    <div>-->
<!--                        <small class="text-secondary">{{ 'appointment_booking_creation_time' | translate }}</small>-->
<!--                        <span class="fs-6 d-block">{{ appointment()!.createdAt|date:'HH:mm':undefined:preferred_lang() }}</span>-->
<!--                    </div>-->
                </div>
                <div class="col text-center break-words">
                    <small class="text-secondary">{{ 'appointment_booking_time' | translate }}</small>
                    <h5 class="m-0 fw-bold">{{ appointment()!.slot }}</h5>
                </div>
            </div>
            <div class="row d-none px-md-0 py-md-3 mx-md-3 d-md-block p-2 border-bottom">
                <div class="col d-flex gap-3">
                    <div>
                        <small class="text-secondary">{{'booking_no_label' | translate}}</small>
                        <span class="d-block">{{ appointment()!.bookingNo }}</span>
                    </div>
                    <div>
                        <small class="text-secondary">{{'appointment_booking_payment_method' | translate}}</small>
                        <span class="d-block">
                            @if (appointment()?.privately) {
                                {{ 'appointment_private_payment' | translate }}
                                <i class="fad fa-money-bill text-success"></i>
                            } @else {
                                {{ 'appointment_sponsored_payment' | translate }}
                                <i class="fad fa-building-shield text-diagnosou-secondary"></i>
                            }

                        </span>
                    </div>
                </div>
            </div>
            <div class="row p-2 px-md-0 py-md-3 mx-md-3">
                <div class="col">
                    <small class="text-secondary">{{ 'appointment_booking_tests' | translate }}</small>
                    <span class="fs-6 d-block">
                        @for (exam of appointment()!.exams; track exam.id; let l = $last) {
                            {{ exam.title?.toUpperCase() }}{{ l ? '' : ', ' }}
                        }
                    </span>
                </div>
                <div class="col text-end d-md-none">
                    <small class="text-secondary">{{'appointment_booking_payment_method' | translate}}</small>
                    <span class="d-block">
                            @if (appointment()?.privately) {
                                {{ 'appointment_private_payment' | translate }}
                                <i class="fad fa-money-bill text-success"></i>
                            } @else {
                                {{ 'appointment_sponsored_payment' | translate }}
                                <i class="fad fa-building-shield text-diagnosou-secondary"></i>
                            }

                        </span>
                </div>
                <div class="col d-none align-items-center d-md-flex gap-2 justify-content-end">
                    @if (appointment()?.canReschedule) {
                        <button class="btn btn-sm fw-bold btn-primary py-1 px-3 rounded-2"
                                (click)="reschedule.emit()">
                            {{ 'booking_reschedule_button' | translate }}
                        </button>
                    }
                    @if (appointment()?.canCancel) {
                        <button class="btn btn-sm fw-bold btn-danger py-1 px-3 rounded-2"
                                (click)="cancel.emit()">
                            {{ 'generic_button_cancel' | translate }}
                        </button>
                    }
                </div>
            </div>
            <div class="d-flex w-100 gap-1 d-md-none">
                @if (appointment()?.canReschedule) {
                    <button class="flex-fill w-50 btn btn-sm fw-bold p-0 rounded-0 btn-reschedule"
                            (click)="reschedule.emit()">
                        {{ 'booking_reschedule_button' | translate }}
                    </button>
                }
                @if (appointment()?.canCancel) {
                    <button class="flex-fill w-50 btn btn-sm fw-bold p-1 rounded-0 btn-dark"
                            (click)="cancel.emit()">
                        {{ 'generic_button_cancel' | translate }}
                    </button>
                }
            </div>

        </div>
    </div>

}
