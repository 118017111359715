<div>
    <div class="datepicker-header bg-gradient">
      <span class="title">{{periodLabel()!|date:dateFormat():undefined:preferred_lang()|titlecase}}</span>
    </div>

    <div class="mat-calendar-header">
      <div class="mat-calendar-controls">
        <span [id]="_periodButtonLabelId" class="cdk-visually-hidden" aria-live="polite">{{periodButtonDescription}}</span>
        <button mat-button type="button" class="mat-calendar-period-button"
                (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
                [attr.aria-describedby]="_periodButtonLabelId">
          <span aria-hidden="true">{{periodButtonText}}</span>
          <svg class="mat-calendar-arrow" [class.mat-calendar-invert]="calendar.currentView !== 'month'"
               viewBox="0 0 10 5" focusable="false" aria-hidden="true">
            <polygon points="0,0 5,5 10,0"/>
          </svg>
        </button>

        <div class="mat-calendar-spacer"></div>

        <ng-content></ng-content>

        <button mat-icon-button type="button" class="mat-calendar-previous-button"
                [disabled]="!previousEnabled()" (click)="previousClicked()"
                [attr.aria-label]="prevButtonLabel">
        </button>

        <button mat-icon-button type="button" class="mat-calendar-next-button"
                [disabled]="!nextEnabled()" (click)="nextClicked()"
                [attr.aria-label]="nextButtonLabel">
        </button>
      </div>
    </div>
  </div>
