<div class="h-100 d-flex flex-column">
    @if (!isExternal) {
        <app-navigation-bar #nav [currentStage]="currentStage"
                            (currentStageChange)="onCurrentStageChange($event)"></app-navigation-bar>

        <ng-container *ngTemplateOutlet="GDPR"></ng-container>

        <div class="m-3 mt-0 m-md-5 d-flex align-items-center">
            <div class="d-none flex-fill d-md-block">
                <button class="btn btn-secondary back-btn fs-6 fw-bold w-auto d-md-block d-none"
                        (click)="nav.goBack()">
                    {{ 'generic_back_btn'|translate }}
                </button>
            </div>
            <button class="fs-6 position-sticky btn btn-gradient fw-bold flex-grow-1 flex-md-grow-0" (click)="close()">
                {{ 'generic_button_submit' | translate }}
            </button>

        </div>
    } @else {
        <ng-container *ngTemplateOutlet="GDPR"></ng-container>
    }
</div>

<ng-template #GDPR>
    <ul class="list-unstyled pt-3 px-4 container position-relative d-flex flex-fill overflow-auto flex-column gap-3">
        @switch (lang()) {
            @case ('el') {
                <li>
                    <h4 class="fw-bold my-2">1. ΠΟΙΟΙ ΕΙΜΑΣΤΕ;</h4>
                    <p>Η DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε. αναγνωρίζει και εκτιμάει την ιδιωτικότητα των χρηστών του ιστότοπου και την εμπιστευτικότητα των προσωπικών τους δεδομένων. Για τον λόγο αυτό η DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε. δεσμεύεται να διαχειρίζεται τα προσωπικά σας δεδομένα με προσοχή, ακεραιότητα και διαφάνεια.</p>
                    <p>Η DIAGNOSOU SINGLE MEMBER P.C., η οποία εδρεύει στην Αγία Παρασκευή Αττικής επί της Κλεισθένοους 39-41, 15343.</p>
                    <p>Η DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε. οι θυγατρικές και οι αδελφικές εταιρείες και οι συνεργάτες της («ΔΙΑΓΝΩΣΟΥ», «εμείς») ενεργούν ως Υπεύθυνοι Επεξεργασίας Δεδομένων που συλλέγουν και χρησιμοποιούν τα Προσωπικά Δεδομένα σας όταν επισκέπτεστε τον ιστότοπό μας.</p>
                    <p>Αυτή η Πολιτική Απορρήτου εξηγεί πως μπορούν να χρησιμοποιηθούν τα Προσωπικά Δεδομένα σας σε αυτό το πλαίσιο. Κατά καιρούς η εν λόγω Πολιτική Απορρήτου μπορεί να τροποποιείται με βάση τους ισχύοντες νόμους, κανονισμούς και οδηγίες για την προστασία των προσωπικών δεδομένων που παρέχονται από τις δημόσιες αρχές («Εφαρμοστέοι νόμοι για την προστασία δεδομένων»).</p>
                    <p>Σε περίπτωση που μας παρέχετε δεδομένα που δεν καλύπτονται από την πολιτική απορρήτου του ιστότοπού μας, αυτά τα δεδομένα θα καλύπτονται από μια διακριτή συμβατική σχέση, καθώς και από ισχύοντες νόμους και κανονισμούς.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">2. ΠΟΙΑ ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ ΣΥΛΛΕΓΟΥΜΕ;</h4>
                    <p>Συλλέγουμε δεδομένα που θα μπορούσαν να σας ταυτοποιήσουν είτε άμεσα (π.χ. Αριθμός Κοινωνικής Ασφάλισης-ΑΜΚΑ και αριθμός κινητού τηλεφώνου) είτε έμμεσα (π.χ. μέσω της διεύθυνσης IP σας). Αυτά είναι Προσωπικά Δεδομένα.</p>
                    <p>Κατά την επίσκεψή σας στον ιστότοπό μας, ενδέχεται να συλλέγονται προσωπικά δεδομένα ανάλογα με τις αλληλεπιδράσεις σας μαζί μας. Διακρίνουμε τα δεδομένα που συλλέγονται αυτόματα και τα δεδομένα που μας παρέχετε.</p>
                    <h5 class="fw-bold my-2">Δεδομένα που συλλέγονται αυτόματα</h5>
                    <h5 class="fw-bold">Αναλυτικά Στοιχεία Χρήσης του Ιστότοπου (Website usage analytics)</h5>
                    <p>Αυτά τα δεδομένα δημιουργούνται μέσω cookies ενώ χρησιμοποιείτε τον ιστότοπο για να παρέχετε πληροφορίες σχετικά με τη σελίδα που επισκεφτήκατε, τον ιστότοπο από τον οποίο προέρχεστε, το βίντεο που παίξατε κ.λπ. Ο ιστότοπός μας εγκαθιστά cookies ή παρόμοιες τεχνολογίες που μας επιτρέπουν να μετράμε το κοινό που επισκέπτεται τον Ιστότοπο, να βελτιώσουμε τις υπηρεσίες μας και την παρουσίαση του ιστότοπού μας για να προτείνουμε την καλύτερη δυνατή εμπειρία στον Χρήστη.</p>
                    <p>Πατήστε εδώ για περισσότερες πληροφορίες σχετικά με την Πολιτική των Cookies μας (Cookies Policy).</p>
                    <h5 class="fw-bold my-2">Δεδομένα τα οποία παρέχετε άμεσα</h5>
                    <p>Όταν ζητάτε να κλείσετε ραντεβού εισάγοντας τον αριθμό του κινητού σας τηλεφώνου, όταν εισάγετε τον αριθμό κοινωνικής ασφάλισης - ΑΜΚΑ για να ελέγξετε αν δικαιούστε στις υπηρεσίες υγείας, όταν εγγράφεστε στα ενημερωτικά δελτία μας, όταν εγγράφεστε στην ιστοσελίδα μας ως γιατρός ή ιατρικό εργαστήριο ή όταν επικοινωνείτε μαζί μας, παρέχετε δεδομένα που μας επιτρέπουν να ανταποκριθούμε στα αιτήματά σας και να σας παρέχουμε τις καλύτερες απαντήσεις.</p>
                    <h5 class="fw-bold my-2">Αιτήματα Επικοινωνίας, prospect support</h5>
                    <table class="table table-bordered">
                        <tbody>
                        <tr>
                            <td><strong>Τι είδους δεδομένα συλλέγουμε</strong></td>
                            <td>Προσωπικά Δεδομένα σχετικά με την ταυτότητα αιτούντων, υποψήφιων ή συνεργατών που επικοινωνούν μαζί μας μέσω των φορμών επικοινωνίας μας και κλεισίματος ραντεβού, όπως για παράδειγμα ΑΜΚΑ, όνομα, επώνυμο, διεύθυνση email, αριθμός τηλεφώνου, χώρα, σκοπός επικοινωνίας και μήνυμα.</td>
                        </tr>
                        <tr>
                            <td><strong>Γιατί τα συλλέγουμε</strong></td>
                            <td>Αυτά τα Προσωπικά Δεδομένα είναι απαραίτητα για το κλείσιμο των ιατρικών ραντεβού σας, την επεξεργασία των αιτημάτων σας και την προσαρμογή των απαντήσεών μας ώστε να ανταποκρίνονται καλύτερα στις απαιτήσεις σας. Εάν συμφωνείτε με αυτό παρέχοντας τη συγκατάθεσή σας, ενδέχεται να χρησιμοποιήσουμε τα προσωπικά σας στοιχεία για να σας στείλουμε πληροφορίες σχετικά με τα προϊόντα, τις υπηρεσίες μας και τις εκδηλώσεις που ενδέχεται να διοργανώνουμε κατά καιρούς. Διασφαλίζουμε ότι κάθε φόρμα επικοινωνίας περιορίζει τη συλλογή Προσωπικών Δεδομένων στα απαραίτητα και υποδεικνύει τις απαιτούμενες πληροφορίες που πρέπει να παρέχουμε.</td>
                        </tr>
                        <tr>
                            <td><strong>Περίοδος Διατήρησης</strong></td>
                            <td>Τα Προσωπικά Δεδομένα που αφορούν τους υποψήφιους πελάτες, αιτούντες ραντεβού ή/και συνεργάτες μας θα διατηρούνται για δέκα (10) χρόνια από τη στιγμή που συλλέγονται ή από την τελευταία επαφή που αποστέλλεται από τον υποψήφιο πελάτη, με την επιφύλαξη τυχόν υποχρεώσεων διατήρησης ή παραγραφής.</td>
                        </tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    <h4 class="fw-bold my-2">3. ΓΙΑΤΙ ΧΡΗΣΙΜΟΠΟΙΟΥΜΕ ΤΑ ΠΡΟΣΩΠΙΚΑ ΣΑΣ ΔΕΔΟΜΕΝΑ;</h4>
                    <p>Μπορούμε να συλλέγουμε και να επεξεργαζόμαστε τα Προσωπικά σας Δεδομένα στις ακόλουθες περιπτώσεις:</p>
                    <ul class="list-disc">
                        <li><strong>Με την συναίνεσή σας</strong> – Αυτό ισχύει όταν μας έχετε δώσει συγκεκριμένα την άδειά σας να χρησιμοποιήσουμε τα προσωπικά σας δεδομένα για να ανταποκριθούμε σε ένα από τα αιτήματά σας. Αυτό ισχύει επίσης όταν αποδέχεστε τα cookies μας – Μάθετε περισσότερα στην Πολιτική μας για τα cookies.</li>
                        <li><strong>Για την εκτέλεση μιας σύμβασης</strong> – για τη σύναψη ή την εκτέλεση συμφωνίας μεταξύ της DIAGNOSOU και εσάς ως (υποψήφιος) πελάτης, ή (υποψήφιος) συνεργάτης, εργαστήριο ή γιατρός ή ως αιτών όσον αφορά τις εργασίες επεξεργασίας, το μάρκετινγκ ή/και κάθε άλλη επαγγελματική σχέση.</li>
                        <li><strong>Το έννομο συμφέρον μας</strong> – Αυτό ισχύει όταν η DIAGNOSOU διεξάγει δραστηριότητες με σκοπό τη συλλογή στατιστικών στοιχείων, το marketing, την προώθηση και την ανάπτυξη ή/και τη βελτίωση των προϊόντων και των υπηρεσιών της DIAGNOSOU, καθώς και την υπεράσπιση των δικαιωμάτων και συμφερόντων της DIAGNOSOU.</li>
                        <li><strong>Συμμόρφωση με νομικές υποχρεώσεις</strong> – Αυτό ισχύει όταν η DIAGNOSOU απαιτείται να επεξεργάζεται δεδομένα για λογιστικούς και φοροτεχνικούς λόγους ή για τη διαχείριση των αιτημάτων ή για την καλύτερη ανταπόκριση μας στην στις περιπτώσεις που επιθυμείτε να ασκήσετε τα δικαιώματά σας.</li>
                    </ul>
                </li>
                <li>
                    <h4 class="fw-bold my-2">4. ΜΕ ΠΟΙΟΥΣ ΜΟΙΡΑΖΟΜΑΣΤΕ ΤΑ ΠΡΟΣΩΠΙΚΑ ΣΑΣ ΔΕΔΟΜΕΝΑ;</h4>
                    <p>Κατά γενικό κανόνα, ενδέχεται να μεταφέρουμε τα προσωπικά σας δεδομένα σε γιατρούς ή/και ιατρικά εργαστήρια για την ολοκλήρωση ενός ραντεβού και δεν παρέχουμε, πουλάμε ή εκμισθώνουμε τα Προσωπικά Δεδομένα σας σε τρίτους για λόγους μάρκετινγκ χωρίς τη ρητή συγκατάθεσή σας. Για παράδειγμα, ενδέχεται να μεταφέρουμε τα προσωπικά σας δεδομένα στον συνεργάτη μας, το Ιατρικό Διαγνωστικά Εργαστήριο με την επωνυμία «LifeCheck» μετά την κράτηση του ραντεβού σας <a href="https://www.lifecheck.gr/el">https://www.lifecheck.gr/el</a>.</p>
                    <p>Ενίοτε, χρειαζόμαστε βοήθεια για να προσφέρουμε την καλύτερη δυνατή εμπειρία στους Χρήστες του Ιστότοπου. Για να γίνει αυτό, βασιζόμαστε σε διάφορους τρίτους παρόχους υπηρεσιών και εργολάβους για την παροχή υπηρεσιών που υποστηρίζουν και βελτιώνουν τον ιστότοπο και τις δραστηριότητές μας, συμπεριλαμβανομένης, χωρίς περιορισμό, της συντήρησης των βάσεων δεδομένων μας, της διανομής μηνυμάτων ηλεκτρονικού ταχυδρομείου και ενημερωτικών δελτίων για λογαριασμό μας και της ανάλυσης δεδομένων (data analysis). Αυτά τα τρίτα μέρη ενδέχεται να έχουν πρόσβαση στα Προσωπικά Δεδομένα σας για τους σκοπούς της εκτέλεσης της σύμβασης ή/και του έργου που έχουν αναλάβει. Ωστόσο, αυτοί οι πάροχοι δεσμέυονται να από τις συμβατικές τους υποχρεώσεις καθώς και από τις υποχρεώσεις εμπιστευτικότητας όπως αυτές ισχύουν από το νόμο για όσο διάστημα κατέχουν ή με οποιοδήποτε τρόπο επεξεργάζονται τα προσωπικά σας δεδομένα.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">5. ΣΕ ΠΟΙΟΕΣ ΠΕΡΙΠΤΩΣΕΙΣ ΜΕΤΑΒΙΒΑΖΟΥΜΕ ΤΑ ΠΡΟΣΩΠΙΚΑ ΣΑΣ ΔΕΔΟΜΕΝΑ;</h4>
                    <p>Μεταβιβάσεις/μεταφορές μεταξύ της DIAGNOSOU, της μητρικής εταιρείας, της θυγατρικής εταιρείας και των συνεργαζόμενων εταιρειών.</p>
                    <p>Η έδρα μας είναι στην Ελλάδα και εργαζόμαστε σε όλη την Ευρωπαϊκή Ένωση. Ως εκ τούτου, αναγνωρίζετε και συναινείτε ότι οποιαδήποτε δεδομένα παρέχετε ή συλλέγουμε ενδέχεται να χρησιμοποιηθούν, να υποβληθούν σε επεξεργασία ή/και να μεταφερθούν στην Ελλάδα ή σε οποιαδήποτε δικαιοδοσία της ΕΕ όπου έχουμε επιχειρησιακές δραστηριότητες βάσει του Ν. 4624/2019 και του Γενικού Κανονισμού Προστασίας Δεδομένων. Εφαρμόζουμε τα κατάλληλα μέτρα για την προστασία των Προσωπικών Δεδομένων σας σε περίπτωση που υπάρχει ανάγκη μεταφοράς των Προσωπικών Δεδομένων σας εκτός ΕΕ, όπως συμφωνίες μεταφοράς δεδομένων που ενσωματώνουν τυπικές ρήτρες και διατάξεις προστασίας προσωπικών δεδομένων (μεταφορά σε τρίτους).</p>
                    <p>Μεταβιβάσεις σε τρίτα μέρη.</p>
                    <p>Ενδέχεται να μοιραστούμε Προσωπικά Δεδομένα με τρίτα μέρη, όπως ορίζεται στην παρούσα Πολιτική Απορρήτου, και κατά συνέπεια να μεταφέρουμε Προσωπικά Δεδομένα εκτός Ευρωπαϊκής Ένωσης. Για παράδειγμα, ενδέχεται να μεταφέρουμε προσωπικά δεδομένα σε πρακτορείο μάρκετινγκ ή επικοινωνίας ή για να μετρήσουμε το κοινό του ιστότοπού μας. Προτού μεταφέρουμε δεδομένα σε τρίτους, βεβαιωνόμαστε ότι τα εν λόγω τρίτα μέρη, τα οποία λαμβάνουν τα δεδομένα, προσφέρουν τα απαραίτητα πρότυπα και μέσα προστασίας δεδομένων που απαιτούνται από τον GDPR, είτε λόγω νομικών υποχρεώσεων είτε λόγω συμφωνιών προστασίας δεδομένων ή συμβατικών πράξεων που έχουν υπογραφεί με τα εν λόγω τρίτα μέρη. Όλες οι διεθνείς μεταφορές δεδομένων πραγματοποιούνται σύμφωνα με τον GDPR.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">6. ΜΕ ΠΟΙΟ ΤΡΟΠΟ ΣΥΜΜΟΡΦΩΝΟΜΑΣΤΕ ΜΕ ΤΟΝ ΝΟΜΟ;</h4>
                    <p>Η DIAGNOSOU συνεργάζεται με κυβερνητικές, δικαστικές και εισαγγελικές αρχές καθώς και με άλλους φορείς και νομικά πρόσωπα ιδιωτικού ή/και δημοσίου δικαίου για την ορθή συμμόρφωση της με το νόμο και κυρίως με τους νόμους για την προστασία των Προσωπικών Δεδομένων. Ενδέχεται να μας ζητηθεί να αποκαλύψουμε και να μοιραστούμε τα Προσωπικά Δεδομένα ενός ατόμου προκειμένου να ανταποκριθούμε σε κάποιο νόμιμο αίτημα ή απαίτηση από δημόσιες αρχές προκειμένου να τηρήσουμε την εθνική ασφάλεια, την εφαρμογή του νόμου ή άλλες νομικές υποχρεώσεις.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">7. ΠΩΣ ΠΡΟΣΤΑΤΕΥΟΥΜΕ ΤΑ ΠΡΟΣΩΠΙΚΑ ΣΑΣ ΔΕΔΟΜΕΝΑ;</h4>
                    <p>Δεσμευόμαστε να διατηρούμε τα Προσωπικά Δεδομένα σας ασφαλή στην καθημερινή μας δραστηριότητα καθώς και όταν επισκέπτεστε τον ιστότοπό μας. Λαμβάνουμε πάντα υπόψη το πλαίσιο και τους σκοπούς της επεξεργασίας, καθώς και την πιθανότητα και τον αντίκτυπο των κινδύνων που σχετίζονται με τα Προσωπικά Δεδομένα σας για να προσαρμόσουμε τα μέτρα ασφαλείας μας. Ως εκ τούτου, εφαρμόζουμε κατάλληλα τεχνικά και οργανωτικά μέτρα και εφαρμόζουμε αυστηρές διαδικασίες και χαρακτηριστικά ασφαλείας για την αποτροπή μη εξουσιοδοτημένης πρόσβασης, απώλειας ή ζημιάς σε δεδομένα. Πάντα είμαστε ιδιαίτερα προσεκτικοί όταν αναθέτουμε σε τρίτους τον χειρισμό των προσωπικών σας δεδομένων και απαιτούμε να συμμορφώνονται με τα ίδια πρότυπα που εφαρμόζουμε εμείς.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">8. ΕΠΕΞΕΡΓΑΖΟΜΑΣΤΕ ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ ΑΝΗΛΙΚΩΝ;</h4>
                    <p>Ο ιστότοπος δεν απευθύνεται σε άτομα κάτω των 18 ετών. Δεν συλλέγουμε εν γνώσει μας Προσωπικά Δεδομένα από ανηλίκους κάτω των 18 ετών που χρησιμοποιούν τον Ιστότοπο. Εάν αντιληφθείτε ότι χρήστης κάτω των 18 ετών έχει χρησιμοποιήσει τον Ιστότοπο, επικοινωνήστε με η DIAGNOSOU στο <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a>. Εάν αντιληφθούμε ότι ένας ανήλικος κάτω των 18 ετών μας έχει παράσχει Προσωπικά Δεδομένα, η DIAGNOSOU θα προβεί στις απαραίτητες ενέργειες για να αφαιρεθούν οριστικά αυτά τα Προσωπικά Δεδομένα.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">9. ΤΑ ΔΙΚΑΙΩΜΑΤΑ ΣΑΣ</h4>
                    <p>Η άσκηση των δικαιωμάτων σας</p>
                    <p>Ανάλογα με το πού βρίσκεστε και σύμφωνα με τον GDPR, μπορείτε να:</p>
                    <ul class="list-disc">
                        <li>Επιβεβαιώσετε την επεξεργασία των Προσωπικών σας Δεδομένων από εμάς,</li>
                        <li>αποκτήσετε πρόσβαση στα Προσωπικά σας Δεδομένα,</li>
                        <li>ελέγξετε, ενημερώσετε, διορθώσετε, διαγράψετε, περιορίσετε, αποκλείσετε και αντιταχθείτε στα Προσωπικά σας Δεδομένα,</li>
                        <li>ασκήσετε το δικαίωμα στην φορητότητα των Προσωπικών σας Δεδομένων,</li>
                        <li>ζητήσετε πληροφορίες από τους ιδιωτικούς και δημόσιους οργανισμούς με τους οποίους έχουμε μοιραστεί τα προσωπικά σας δεδομένα,</li>
                        <li>ζητήσετε πληροφορίες σχετικά με με τη δυνατότητα μη παροχής συγκατάθεσης και για τις συνέπειες της άρνησης,</li>
                        <li>ανακαλέσετε την συναίνεσή σας.</li>
                    </ul>
                </li>
                <li>
                    <h4 class="fw-bold my-2">10. ΠΕΡΙΟΡΙΣΜΟΣ ΣΤΗ ΧΡΗΣΗ ΤΗΣ ΛΙΣΤΑΣ ΜΕ ΤΑ MAIL ΜΑΣ</h4>
                    <p>Εάν δεν επιθυμείτε πλέον να λαμβάνετε νέες ειδοποιήσεις, ενημερωτικά δελτία ηλεκτρονικού ταχυδρομείου ή άλλες μελλοντικές προωθητικές ανακοινώσεις από εμάς, μπορείτε να επιλέξετε να καταργήσετε την εγγραφή σας κάνοντας κλικ στον σύνδεσμο στο κάτω μέρος οποιασδήποτε τέτοιας επικοινωνίας και ακολουθώντας τις σχετικές οδηγίες.</p>
                    <p>Εναλλακτικά, μπορείτε να μας ειδοποιήσετε μέσω email <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a> με τη φράση "αφαίρεση από τη diagnosou.gr" στην κεφαλίδα θέματος και θα αφαιρέσουμε το όνομά σας από τη λίστα παραληπτών email μας εντός πέντε (5) ημερών. Δεν είναι δυνατή η εξαίρεση για την παροχή απαραίτητων υπηρεσιών ή για άλλες διοικητικές ειδοποιήσεις και ειδοποιήσεις συναλλαγών.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">11. ΠΩΣ ΝΑ ΕΠΙΚΟΙΝΩΝΗΣΕΤΕ ΜΑΖΙ ΜΑΣ</h4>
                    <p>Εάν θέλετε να ασκήσετε ένα από τα δικαιώματά σας, επικοινωνήστε πρώτα μαζί μας στη διεύθυνση: <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a></p>
                    <p>Επισυνάψτε ένα αντίγραφο του δελτίου ταυτότητάς σας ή οποιουδήποτε άλλου έγκυρου εγγράφου ταυτοποίησης με την υπογραφή σας, ώστε να μπορούμε να βεβαιωθούμε ότι τυχόν αιτήματα σχετικά με τα Προσωπικά Δεδομένα σας προέρχονται πράγματι από εσάς. Ενδέχεται να διατηρήσουμε ένα αρχειοθετημένο αντίγραφο του αιτήματός σας σύμφωνα με τους ισχύοντες νόμους περί προστασίας δεδομένων.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">12. ΚΑΤΑΓΓΕΛΙΑ ΣΤΙΣ ΑΡΜΟΔΙΕΣ ΑΡΧΕΣ</h4>
                    <p>Μπορείτε επίσης να υποβάλετε καταγγελία στην τοπική εθνική αρχή προστασίας δεδομένων σας εάν θεωρείτε ότι οποιαδήποτε επεξεργασία των Προσωπικών Δεδομένων σας παραβιάζει τις απαιτήσεις του GDPR. Ωστόσο, σας προσκαλούμε να επικοινωνήσετε μαζί μας πριν το πράξετε.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">13. ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ – ΥΠΕΥΘΥΝΟΣ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</h4>
                    <p>Εάν έχετε ερωτήσεις σχετικά με την εν λόγω Πολιτική Απορρήτου ή τον τρόπο επεξεργασίας των Προσωπικών Δεδομένων σας, επικοινωνήστε με τον Υπεύθυνο Προστασίας Δεδομένων της DIAGNOSOU, στη DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ ΙΚΕ, <a href="https://www.google.com/maps/search/KLEISTHENOUS%2039-41,%20AGIAS%20PARASKEVIS%20/%20ATTIKIS,%2015343" target="_blank">Κλεισθένους 39-41, Αγία Παρασκευή/ Αττικής 15343, Ελλάδα</a>, ή στο <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a></p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">14. ΑΛΛΑΓΕΣ ΣΤΗΝ ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ</h4>
                    <p>Αυτή η Πολιτική Απορρήτου ισχύει από την ημερομηνία της τελευταίας αναθεώρησής της. Διατηρούμε το δικαίωμα να αλλάξουμε αυτήν την Πολιτική Απορρήτου ανά πάσα στιγμή. Όταν κάνουμε αλλαγές, θα δημοσιεύσουμε την τροποποιημένη Πολιτική Απορρήτου στον Ιστότοπο και θα τίθεται σε ισχύ αμέσως.</p>
                    <p>Version 1 – Νοέμβριος, 2024</p>
                </li>
            }
            @default {
                <li>
                    <h4 class="fw-bold my-2">1. WHO WE ARE?</h4>
                    <p>DIAGNOSOU SINGLE MEMBER P.C. values the privacy of the website’s users and the confidentiality of their data. That is why DIAGNOSOU SINGLE MEMBER P.C. is committed to treating your Personal Data with care, integrity, and transparency.</p>
                    <p>DIAGNOSOU SINGLE MEMBER P.C., which is headquartered at Kleisthenous 39-41, Agias Paraskevis / Attiki, 15343</p>
                    <p>DIAGNOSOU SINGLE MEMBER P.C. and its subsidiaries and partners (“DIAGNOSOU”, “we”, “us” and “our”), act as Data Controllers collecting and using your Personal Data when you visit our website [.]</p>
                    <p>This Privacy Policy explains how your Personal Data may be used in this context. It may be amended from time to time based on applicable data protection laws, regulations, and guidelines provided by public authorities (“Applicable Data Protection Laws”).</p>
                    <p>Should you provide us with data not covered by our website privacy policy, such data shall be covered by a distinct contractual relationship, as well as applicable laws and regulations.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">2. WHAT DATA DO WE COLLECT?</h4>
                    <p>We collect data that could identify you either directly (e.g. Social Security Number- AMKA and mobile phone number) or indirectly (e.g. through your IP address). Those are Personal Data.</p>
                    <p>While visiting our website, personal data may be collected depending on your interactions with us. We distinguish the data collected automatically and the data you provide to us.</p>
                    <h5 class="fw-bold my-2">Data automatically collected</h5>
                    <h5 class="fw-bold">Website usage analytics</h5>
                    <p>Those data are generated through cookies while you are using the website to provide information regarding the page you visited, the website you are coming from, the video that you played, etc. Our website installs cookies or similar technologies that allow us to measure the audience of our publications, improve the interface and the presentation of our website to propose the best user experience possible.</p>
                    <p>Click here for more information on the Cookies Policy.</p>
                    <h5 class="fw-bold my-2">Data that you provide directly</h5>
                    <p>When you request to book an appointment by inserting your mobile phone number, when you insert your social security number -AMKA to check whether you are entitled to any health services, when you subscribe to our newsletters, when you register to our website as a doctor or medical lab or when you contact us, you provide data allowing us to respond to your requests and to provide you with the best answers.</p>
                    <h5 class="fw-bold my-2">Contact requests, prospect support</h5>
                    <table class="table table-bordered">
                        <tbody>
                        <tr>
                            <td><strong>The type of data we collect</strong></td>
                            <td>Personal Data concerning the identity of requestors, prospects or partners contacting us via our contact forms, including first name, last name, email address, phone number, country, contact purpose and message.</td>
                        </tr>
                        <tr>
                            <td><strong>Why do we use them</strong></td>
                            <td>These Personal Data are necessary to process your request and adapt our answers to best suit your demand. If you agree to this by providing your consent, we may use your personal information to send you information about our products, services, and the events we may organize from time to time. We make sure that each contact form limits the collection of Personal Data to what is necessary and indicates the required information to provide.</td>
                        </tr>
                        <tr>
                            <td><strong>Retention</strong></td>
                            <td>Personal Data concerning our prospects shall be kept for ten (10) years from the time they are collected or from the last contact sent by the prospect, without prejudice to any retention obligations or limitation periods.</td>
                        </tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    <h4 class="fw-bold my-2">3. WHY ARE WE ALLOWED TO USE YOUR DATA?</h4>
                    <p>We can collect and process your Personal Data when one of the following applies:</p>
                    <ul class="list-disc">
                        <li><strong>Your consent</strong> – This applies when you have specifically given us your permission to use your personal data for responding to one of your requests. This is also applicable when you accept our cookies – Find out more in our Cookie Policy.</li>
                        <li><strong>The performance of an agreement</strong> – for the entering or the performance of an agreement between DIAGNOSOU and yourself representing a (prospect) customer, a (prospect) partner, lab or doctor or as an applicant with regards to processing operations, marketing and relationship monitoring;</li>
                        <li><strong>Our legitimate interest</strong> – This applies where DIAGNOSOU conduct operations for the purpose of compiling statistics, marketing, promoting, and developing and/or improving DIAGNOSOU’s products and services, as well the defense of DIAGNOSOU’s rights and interests;</li>
                        <li><strong>Compliance with legal obligations</strong> – This applies where DIAGNOSOU is required to process data with regard to invoicing and accounting purposes or the management of requests or for responding to the exercise of your rights.</li>
                    </ul>
                </li>
                <li>
                    <h4 class="fw-bold my-2">4. WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</h4>
                    <p>As a general rule, we may transfer your personal data to doctors and/or medical labs for the completion of an appointment and we do not provide, sell or lease your Personal Data to any third parties for their marketing use without your express consent. For example, we may transfer your personal data to our partner, the Medical Diagnostic Laboratories “LifeCheck” after the booking of the appointment <a target="_blank" href="https://www.lifecheck.gr/en">https://www.lifecheck.gr/en</a></p>
                    <p>From time to time, we need some help to propose the best user experience. When this happens, we rely on various third-party service providers and contractors to provide services that support the website and our operations, including, without limitation, maintenance of our databases, distribution of emails and newsletters on our behalf, data analysis. Such third parties may have access to your Personal Data for the purpose of performing the service for which they have been engaged. However, such service providers shall remain under contractual obligations, particularly confidentiality obligations, for as long as they hold relevant Personal Data.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">5. IN WHAT INSTANCE DO WE TRANSFER YOUR PERSONAL DATA?</h4>
                    <h5 class="fw-bold my-2">Transfers between DIAGNOSOU, parent company, its subsidiaries and partners</h5>
                    <p>Our headquarters are in Greece and we work all over European Union. Therefore, you acknowledge and consent that any data you provide, or we collect may be used, processed and/or transferred to Greece or to any EU jurisdiction where we have operational activities under the L. 4624/2019 and the General Data Protection Regulation.</p>
                    <p>We implement appropriate measures to protect your Personal Data in case there is a need to transfer your Personal Data outside of EU such as data transfer agreements that incorporate standard data protection clauses (transfer to third parties).</p>
                    <h5 class="fw-bold my-2">Transfers to third parties</h5>
                    <p>We may share Personal Data with third parties, as established in this Privacy Policy, and consequently transfer Personal Data outside European Union. For example, we may transfer personal data to marketing or communication agency or to measure the audience of our website.</p>
                    <p>Before we transfer any data to third parties, we make sure that said third parties, which receive the data, offer the necessary standards of data protection required by GDPR, either due to mandatory legal requirements or due to data protection agreements or contractual instruments signed with the third parties in question. All international data transfers are undertaken in accordance with the GDPR.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">6. HOW DO WE COMPLY WITH LAW?</h4>
                    <p>DIAGNOSOU cooperates with government and law enforcement officials and private parties to enforce and comply with the law. We may be required to disclose the Personal Data of an individual in response to a lawful request by public authorities, to meet national security, law enforcement or other legal requirements.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">7. HOW DO WE SECURE YOUR DATA?</h4>
                    <p>We are committed to keeping your Personal Data secure in our daily activity and when you visit our website. We always consider the context and purposes of processing as well as the likelihood and impact of risks relating to your Personal Data to adapt our security measures. Therefore, we implement appropriate technical and organizational measures and apply strict procedures and security features to prevent unauthorized access, loss, or damage to data. We always use extra caution when we entrust third parties to handle your personal data and we require that they abide by the same standards as we apply.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">8. DO WE PROCESS MINOR’S DATA?</h4>
                    <p>The Website is not directed towards persons under the age of 18. We do not knowingly collect Personal Data from minors under the age of 18 who use the Website. If you become aware that a user under the age of 18 has used the Website, please contact DIAGNOSOU at <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a>. If we become aware that a minor under 18 has provided us with Personal Data, DIAGNOSOU will take the necessary steps to have that Personal Data irrevocably removed.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">9. YOUR RIGHTS</h4>
                    <h5 class="fw-bold my-2">Exercise your rights</h5>
                    <p>Depending where you are located and in accordance with the GDPR, you may:</p>
                    <ul class="list-disc">
                        <li>confirm the existence of data processing;</li>
                        <li>access your Personal Data;</li>
                        <li>review, update, correct, delete, restrict, block, object your Personal Data;</li>
                        <li>request data portability pertaining to your Personal Data;</li>
                        <li>request information from public and private entities with which we have shared your Personal Data;</li>
                        <li>request information on the possibility of not providing consent and on the consequences of refusal;</li>
                        <li>revoke your consent.</li>
                    </ul>
                    <h5 class="fw-bold my-2">Limited use in our email list</h5>
                    <p>If you no longer wish to receive new notices, email newsletters or other future promotional communications from us, you may choose to unsubscribe by clicking on the link at the bottom of any such communication and following the instructions thereto.</p>
                    <p>Alternatively, you may notify us by email <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a> with the phrase “remove from diagnosou.gr” in the subject header, and we will remove your name from our email recipient list within five (5) days. Opt-out is not possible for necessary services or other administrative and transactional notices.</p>
                    <h5 class="fw-bold my-2">How to contact us</h5>
                    <p>If you like to exercise one of your rights, please contact us first at: <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a></p>
                    <p>Please attach a copy of your identification card or any other valid identification document with your signature, so that we may ascertain that any requests concerning your Personal Data indeed originate from you. We may retain an archived copy of your request in accordance with Applicable Data Protection Laws.</p>
                    <h5 class="fw-bold my-2">Complaint to Authorities</h5>
                    <p>You may also file a complaint with your local national data protection authority if you consider that any processing of your Personal Data infringes upon the requirements of the GDPR. However, we invite you to contact us prior to doing so.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">10. CONTACT US – DATA PROTECTION OFFICER</h4>
                    <p>If you have questions regarding this Privacy Policy or how your Personal Data are processed, please contact DIAGNOSOU’s Data Protection Officer, at DIAGNOSOU Single Member P.C., <a href="https://www.google.com/maps/search/KLEISTHENOUS%2039-41,%20AGIAS%20PARASKEVIS%20/%20ATTIKIS,%2015343" target="_blank">Kleisthenous 39-41, Agias Paraskevis / Attiki, 15343, Greece</a>, or <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a>.</p>
                </li>
                <li>
                    <h4 class="fw-bold my-2">11. CHANGES TO PRIVACY POLICY</h4>
                    <p>This Privacy Policy is effective as of the date of its last revision. We reserve the right to change this Privacy Policy at any time. When we make changes, we will post the changed Privacy Policy at the Website, and it will become effective immediately.</p>
                    <p>Version 1 – November, 2024</p>
                </li>
            }
        }

    </ul>
</ng-template>
