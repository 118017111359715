import {
    AfterViewInit,
    Component, computed, effect,
    ElementRef,
    Input,
    model,
    OnDestroy,
    signal,
    ViewChild
} from '@angular/core';
import {CommonModule} from "@angular/common";
import {fade} from "../../animations/enter-leave.animation";

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [
        CommonModule
    ],
    animations: [fade],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss'
})
export class ModalComponent implements AfterViewInit, OnDestroy {

    @Input() headerTitle: string = '';

    @Input() modalClass: string = '';
    @Input() headerClass: string = '';
    @Input() footerClass: string = '';
    @Input() bodyClass: string = '';
    @Input() backdropClass: string = '';

    @Input() hasCloseButton: boolean = true;
    @Input() closeWithBackdrop: boolean = true;
    @Input() hasPadding: boolean = true;
    @Input() hasFooter: boolean = true;
    @Input() hasHeader: boolean = true;

    @Input() full: boolean = false;

    @Input() attachOnBody: boolean = false;


    public open = model<boolean>(false);
    protected hidden = true;
    private hiddenTimeout: NodeJS.Timeout|undefined;

    private _attachedOnBody: boolean = false;
    private body = signal<HTMLBodyElement|null>(null)

    @ViewChild('wrapper') modalWrapper: ElementRef | undefined;

    constructor() {
        effect(() => {
            if (this.hiddenTimeout) {
                clearTimeout(this.hiddenTimeout);
            }
            if (this.open()) {
                this._handleAttachment();
                this?.body()?.classList.add('overflow-hidden');
                this.hidden = false;
                return;
            }

            this.hiddenTimeout = setTimeout(() => {
                this.hidden = true;
            }, 300);

            this?.body()?.classList.remove('overflow-hidden');
        });
    }

    ngAfterViewInit() {
        this.body.set(document.getElementsByTagName('body')[0]);
        this._handleAttachment();
    }

    ngOnDestroy() {
        this.body()?.classList.remove('overflow-hidden');
    }

    public show() {
        this.open.set(true);
    }

    public hide() {
        this.open.set(false);
    }

    private _handleAttachment() {
        if (this.attachOnBody && !this._attachedOnBody) {
            this.body()?.appendChild(this.modalWrapper?.nativeElement);
            this._attachedOnBody = true;
        }
    }
}
