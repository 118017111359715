import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpServiceBase} from "../classes/HttpServiceBase";
import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {omitBy} from "lodash";

@Injectable({providedIn: 'root'})
export class UserService extends HttpServiceBase {
    protected override baseUrl: string = "/user";

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
    ) {
        super();
        this.assign(this);
    }

    public sendCode(params: Object): Observable<any> {
        return this._http.post(this.baseUrl + '/login/sendCode_v3', params)
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public verifyCode(params: Object): Observable<any> {
        return this._http.post(this.baseUrl + '/login/verify', params)
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public sendSsnForValidation(ssn: string, labId: string, exams: string[]): Observable<any> {
        return this._http.post(this.baseUrl + '/ssn', {ssn, labId, exams})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }


    public sendMessage(message: string, screen: string, phoneNumber?: string): Observable<any> {
        return this._http.post(this.baseUrl + '/message', omitBy({message, screen, phoneNumber}, (value) => !value))
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

}
