import {Component, OnDestroy} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {RevolabButtonComponent} from '../../core/components/revolab-button/revolab-button.component';
import {NavigationBarComponent} from "../../core/components/navigation-bar/navigation-bar.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SessionStorageService} from "ngx-webstorage";
import {LabService} from "../../core/services/lab.service";
import moment from "moment";
import {NearbyLab} from "../../core/interfaces/Lab";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-sms-confirmation',
    standalone: true,
    imports: [NgbModule, CommonModule, RevolabButtonComponent, NavigationBarComponent, NgOptimizedImage, TranslateModule, RouterLink],
    templateUrl: './sms-confirmation.component.html',
    styleUrl: './sms-confirmation.component.scss'
})
export class SmsConfirmationComponent implements OnDestroy {
    message1: string = '';
    message2: string = '';

    thanksMsg: boolean = false;
    bookingConf: boolean = false;
    summary: boolean = false;
    summaryData: { lab: Partial<NearbyLab>|undefined, date: string, bookingNo: string } | undefined

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private session: SessionStorageService,
        private labService: LabService
    ) {
        this.route.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
            this.thanksMsg = !!params['thanks'];
            this.bookingConf = !!params['bookingConf'];
            this.summary = !!params['summary'];

            this.message1 = params['message1'] || '';
            this.message2 = params['message2'] || '';

            if (this.summary && !this.summaryData?.bookingNo) {
                this.labService.retrieveActiveAppointment(this.labService.selectedTests()?.map(t => t.id))
                    .subscribe((res) => {
                        if (res.success) {
                            this.summaryData = {
                                lab: res.appointment?.lab,
                                date: moment(res.appointment?.date).startOf('day')
                                    .add(res.appointment?.slot?.split(":")[0] || 0, 'hours')
                                    .add(res.appointment?.slot?.split(":")[1] || 0, 'minutes')
                                    .format("dddd DD MMMM, HH:mm"),
                                bookingNo: res.appointment?.bookingNo
                            }
                        }
                    })
            }
        });

        this.summaryData = this.session.retrieve("appointment_summary");
    }


    ngOnDestroy() {
        // Book flow is completed so clear the user selections
        this.labService.clearStoredData();
    }

    okayClick() {
        console.log(this.bookingConf)
        if (this.bookingConf) {
            this.router.navigate(['/appointments']);
            return;
        }
        this.router.navigate(['/'], {queryParams: {back: "true"}});
    }

}
