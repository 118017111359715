import {DBDoc} from "./Generic";
import {Lab} from "./Lab";
import {Exam} from "./Exam";

export interface Appointment extends DBDoc {
    bookingNo: string;
    canCancel?: boolean;
    canSchedule?: boolean;
    canReschedule?: boolean;
    privately?: boolean;
    status: AppointmentStatus;
    lab: Partial<Lab>;
    date: Date | string;
    slot: string;
    exams: Partial<Exam>[];
}

export enum AppointmentStatus {
    NEW = 'new',
    BOOKED = 'booked',
    RESCHEDULE = 'reschedule',
    CANCELLED = 'cancelled',
}

