@switch (device) {
    @case ("desktop") {
        <div class="header-container py-4" style="width: 100%;">
            <img ngSrc="assets/images/Logo black.svg"
                 class="img-fluid"
                 height="40" width="286"
                 alt="diagnosou-logo">
            <div>
                <div class="title">
                    {{ 'intro_promo_title' | translate }}
                </div>
                <div class="subtitle">
                    {{ 'gov_sponsored_program' | translate }}
                </div>
            </div>
            <a class="btn btn-gradient" href="/test-select">
                {{ 'intro_action_button_title'|translate }}
            </a>
        </div>
    }
    @case ("mobile") {
        <div class="header-container">

            <a class="btn shadow btn-gradient" href="/test-select">
                {{ 'intro_action_button_title' | translate }}
            </a>
        </div>
    }
}
