import {
    HttpEvent,
    HttpHandler, HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {firstValueFrom, Observable, of, tap} from "rxjs";
import {LocalStorageService} from "ngx-webstorage";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {LoadingService} from "../services/loading.service";
import {BackendService} from "../services/backend.service";

@Injectable()
export class HttpRequestsInterceptor implements HttpInterceptor {

    constructor(
        private backendService: BackendService,
        private _lcStorage: LocalStorageService,
        private _loadingService: LoadingService,
        private _router: Router
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.headers.get("skip")) {
            return next.handle(req);
        }

        let extraHeaders = req.headers ?? new HttpHeaders();

        if (this._lcStorage.retrieve('session_token')) {
            extraHeaders = extraHeaders.set('x-session-token', this._lcStorage.retrieve('session_token'));
        }
        if (this._lcStorage.retrieve('installationId')) {
            extraHeaders = extraHeaders.set('x-installation-id', this._lcStorage.retrieve('installationId'));
        }

        if (req.method?.toLowerCase() === 'post' && !(req.body instanceof FormData)) {
            req = req.clone({
                headers: extraHeaders,
                body: {lang: this._lcStorage.retrieve("preferred_lang") || 'el', ...req.body}
            });
        } else {
            req = req.clone({headers: extraHeaders});
        }


        const Req: HttpRequest<any> = req.clone({url: environment.serverUrl + req.url});

        return next.handle(Req).pipe(
            tap({
                // Succeeds when there is a response; ignore other events
                next: (event) => {
                    if (event instanceof HttpResponse) {
                        // this.removeRequest(Req);
                        return event;
                    }

                    return null;
                },
                // Operation failed; error is an HttpErrorResponse
                error: async (err: any) => {
                    this._loadingService.isLoading.next(false);
                    if (err.status === 401 && err.error?.subCode !== "USER_NOT_FOUND") {
                        return of(err);
                    }
                    if (err.status === 403) {
                        this._router.navigate(['/']);
                        // this._toasterService.warning("Warning", "An error occurred, please refresh the page and try again");
                        return of(err);
                    }

                    switch (err.error?.subCode) {
                        case "INSTALLATION_NOT_FOUND":
                        case "MISSING_INSTALLATION_MIDDLECHECK":
                            // just in case to break infinite loops
                            if (req.url.includes('/installation')) {
                                break;
                            }
                            this._lcStorage.clear('installationId');
                            const installationRes = await firstValueFrom(this.backendService.getInstallation());
                            if (installationRes.installationId) {
                                this._lcStorage.store('installationId', installationRes.installationId);
                                window.location.reload();
                                return of();
                            }
                            break;
                        case "USER_NOT_FOUND":
                            if (!this._lcStorage.retrieve('session_token')) {
                                break;
                            }
                            this._lcStorage.clear('session_token');
                            this._lcStorage.clear('uid');
                            window.location.reload();
                            return of();

                    }

                    // let error = err.message || err.error?.message || err.statusText || err.toString();

                    // this._toasterService.error("An unknown error occurred");

                    return of(err);
                }
            })
        );
    }
}
