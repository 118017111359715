<div class="container flex-fill p-3">

    @for (appointment of appointments; track appointment.id) {
        <div class="row">
            <div class="col-12 mb-3">
                <app-appointment-card [appointment]="appointment"
                                      (reschedule)="requestReschedule(appointment)"
                                      (cancel)="requestCancellation(appointment)">
                </app-appointment-card>
            </div>
        </div>
    } @empty {
        <div class="row" [hidden]="loading()">
            <div class="col-12 text-center fw-bold text-diagnosou-secondary py-5 opacity-50">
                <i class="far fa-calendar-clock fa-4x mb-3"></i>
                <h3 class="text-center">{{ 'appointments_list_empty_title' | translate }}</h3>
                <small>{{ 'appointments_list_empty_subtitle' | translate }}</small>
            </div>
        </div>
    }

    @if (loading()) {
        @for (i of [].constructor(3); track $index) {
            <div class="row">
                <div class="col-12 mb-3 placeholder-glow opacity-50">
                    <div [ngStyle]="{height: '250px'}" class="placeholder w-100 rounded-3"></div>
                </div>
            </div>
        }
    }

    <div #loadMore [hidden]="!hasNextPage || loading()">
        <div class="col-12 text-center mb-3">
            <span class="text-diagnosou-primary fw-bold">
                {{ 'loading_more_appointments' | translate }}
            </span>
        </div>
    </div>


</div>

<app-modal #rescheduleModal (openChange)="resetReason()">
    <ng-container header>
        <h4 class="m-0 text-diagnosou-primary">{{ 'appointment_reschedule_question_title' | translate }}</h4>
    </ng-container>
    <ng-container body>
        <label id="reschedule-radio-group-label"
               class="fs-5 mb-3">
            {{ 'reschedule_reasons_list_title' | translate }}
        </label>
        <mat-radio-group
            aria-labelledby="reschedule-radio-group-label"
            class="d-flex flex-column"
            [(ngModel)]="selectedReason">
            @for (reason of rescheduleReasons; track reason.id) {
                <mat-radio-button [class.text-diagnosou-accent]="selectedReason?.id === reason.id"
                                  [value]="reason">
                    {{ reason.title }}
                </mat-radio-button>
            }
        </mat-radio-group>
        @if (selectedReason?.hasText) {
            <div class="row px-3 pt-3">
                <div class="col-12 col-md-6">
                    <textarea class="form-control"
                              rows="4"
                              [(ngModel)]="userComments"
                              placeholder="{{'other_cancel_reason_comment' | translate}}">
                    </textarea>
                </div>
            </div>
        }
    </ng-container>
    <div footer class="gap-2 d-flex justify-content-end">
        <button class="btn btn-sm btn-secondary" (click)="rescheduleModal.hide()">
            {{ 'generic_back_btn' | translate }}
        </button>
        <button class="btn confirm-button btn-sm"
                [disabled]="!selectedReason?.id"
                (click)="rescheduleAppointment(currentAppointment!)">
            {{ 'appointment_reschedule_confirm_button' | translate }}
        </button>
    </div>
</app-modal>

<app-modal #cancellationModal (openChange)="resetReason()">
    <ng-container header>
        <h4 class="m-0 text-diagnosou-primary">{{ 'appointment_cancel_question_title' | translate }}</h4>
    </ng-container>
    <ng-container body>
        <label id="cancel-radio-group-label"
               class="fs-5 mb-3">
            {{ 'cancellation_reasons_list_title' | translate }}
        </label>
        <mat-radio-group
            aria-labelledby="cancel-radio-group-label"
            class="d-flex flex-column"
            [(ngModel)]="selectedReason">
            @for (reason of cancellationReasons; track reason.id) {
                <mat-radio-button [class.text-diagnosou-accent]="selectedReason?.id === reason.id"
                                  [value]="reason">
                    {{ reason.title }}
                </mat-radio-button>
            }
        </mat-radio-group>
        @if (selectedReason?.hasText) {
            <div class="row px-3 pt-3">
                <div class="col-12 col-md-6">
                    <textarea class="form-control"
                              rows="4"
                              [(ngModel)]="userComments"
                              placeholder="{{'other_cancel_reason_comment' | translate}}">
                    </textarea>
                </div>
            </div>
        }
    </ng-container>
    <div footer class="gap-2 d-flex justify-content-end">
        <button class="btn btn-sm btn-secondary" (click)="cancellationModal.hide()">
            {{ 'generic_back_btn' | translate }}
        </button>
        <button class="btn cancel-button btn-sm"
                [disabled]="!selectedReason?.id"
                (click)="cancelAppointment(currentAppointment!)">
            {{ 'appointment_cancel_confirm_button' | translate }}
        </button>
    </div>
</app-modal>
