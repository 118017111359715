<div class="d-flex align-items-center gap-2 gap-md-3 menu-container">
    @if (authenticated) {
        <div class="cursor-pointer" [matMenuTriggerFor]="menu">
            <i class="fa fa-user me-1" [class.text-white]="!dark"></i>
            <span class="fw-bold d-none d-lg-inline-block" [class.text-white]="!dark">{{ 'tab_item_account' | translate }}</span>
        </div>
    } @else {
        <a routerLink="/mobile-verification" [class.text-white]="!dark" [class.text-black]="dark">
            <i class="fa fa-sign-in me-1"></i>
            <b class="d-none d-lg-inline-block">{{ 'account_item_login' | translate }}</b>
        </a>
    }
    <a routerLink="/contact" [class.text-white]="!dark" [class.text-black]="dark">
        <i class="far fa-envelope me-md-1"></i>
        <b class="d-none d-lg-inline-block">{{ 'account_item_contact_us' | translate }}</b>
    </a>
    <div class="cursor-pointer fw-bold text-nowrap p-1"
         [class.text-white]="!dark"
         (click)="$event.stopPropagation()"
         [matMenuTriggerFor]="langMenu">
        <i class="fa fa-earth-america"></i>
        <small class="ms-1">{{ lang?.locale | uppercase }}</small>
    </div>
    <mat-menu #langMenu="matMenu">
        @for (l of languages; track $index) {
            <button (click)="changeLang(l)" mat-menu-item>
                <i class="me-1 rounded-circle fi fis fi-{{l.code}}"></i>
                <span>{{ l.name | titlecase }}</span>
            </button>
        }
    </mat-menu>
</div>
<mat-menu #menu="matMenu" [xPosition]="'before'">
    <a routerLink="/appointments" mat-menu-item>
        <i class="far fa-calendar-clock"></i>
        {{ 'bookings_list_screen_title' | translate }}
    </a>

    <button (click)="logout()" mat-menu-item>
        <i class="far fa-sign-out-alt"></i>
    {{ 'account_item_logout' | translate }}
    </button>
</mat-menu>

