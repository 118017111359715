import {Component, ElementRef, input, signal} from '@angular/core';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../core/services/navigation.service";
import {UserService} from "../../core/services/user.service";
import {finalize} from "rxjs";
import {LoaderComponent} from "../../core/components/loader/loader.component";
import Swal from "sweetalert2";
import {MarkdownPipe} from "../../core/pipes/markdown.pipe";
import {LocalStorageService} from "ngx-webstorage";

@Component({
    selector: 'app-contact',
    standalone: true,
    imports: [
        FormsModule,
        TranslateModule,
        RouterLink,
        LoaderComponent,
        MarkdownPipe,
    ],
    templateUrl: './contact.component.html',
    styleUrl: './contact.component.scss'
})
export class ContactComponent {

    public comments: string = "";
    public phoneNumber: string = "";
    public loading = signal(false);
    public authenticated: boolean;

    constructor(
        private userService: UserService,
        private translateService: TranslateService,
        private localStorageService: LocalStorageService,
        public navigation: NavigationService
    ) {
        this.authenticated = !!this.localStorageService.retrieve("session_token");
    }

    send() {
        this.loading.set(true);
        if (this.authenticated) {
            this.phoneNumber = "";
        }
        this.userService.sendMessage(this.comments, "contact_web", this.phoneNumber?.trim())
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe(res => {
                if (!res?.success) {
                    return;
                }
                this.comments = "";
                this.phoneNumber = "";
                Swal.fire({
                    title: this.translateService.instant('contact_us_success_title'),
                    text: this.translateService.instant('contact_us_success_subtitle'),
                    icon: 'success',
                    confirmButtonText: this.translateService.instant('generic_button_close'),
                    customClass: {
                        confirmButton: 'btn btn-gradient fw-bold'
                    },
                    timer: 5000
                })
            })
    }

    sanitizePhone(input: HTMLInputElement) {
        const phoneNumber = this.phoneNumber
            ?.replace(/(?!^\+)[^\d]/g, '')
            .replace(/\s+/g, ' ')
            .trim();
        if (phoneNumber === this.phoneNumber) {
            return;
        }
        this.phoneNumber = phoneNumber;
        input.value = this.phoneNumber;
    }

}
