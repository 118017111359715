<div class="d-flex flex-column h-100 w-100 position-relative">

    <app-navigation-bar [currentStage]="currentStage" #nav
                        (currentStageChange)="onCurrentStageChange($event)"></app-navigation-bar>

    @defer (when !!nav.searchInput()) {
        <div id="map" class="w-100 flex-fill">
            <app-map [(currentStage)]="currentStage" #map [searchInput]="nav.searchInput()"></app-map>
        </div>


        <button class="btn btn-secondary m-5 shadow back-btn fs-6 fw-bold w-auto d-md-block d-none"
                [hidden]="map.labSelected"
                (click)="nav.goBack()">
            {{'generic_back_btn'|translate}}
        </button>
    }
</div>
