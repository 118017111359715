<div class="d-flex flex-column gap-4 h-100" xmlns="http://www.w3.org/1999/html">
    <app-navigation-bar #nav [currentStage]="currentStage"
                        (currentStageChange)="onCurrentStageChange($event)"></app-navigation-bar>
    <div class="container-fluid px-3 px-md-5 flex-fill overflow-auto"
         [class.justify-content-center]="loading()"
         [class.align-items-center]="loading()">

        <app-loader [loading]="loading()"></app-loader>
        @if (!loading() && labService.selectedLab() && (labService.selectedTests()?.length || rescheduling())) {
            <div class="row gap-2 align-items-baseline">
                <div
                    class="lab-details mt-lg-3 col-lg-4 align-self-start col-12 mb-lg-0 d-flex rounded-3 p-3 shadow-sm flex-column gap-2">
                    <div
                        class="d-flex m-0 align-items-center gap-2 justify-content-center justify-content-lg-start">
                        <img src="{{labService.selectedLab()?.logo}}" [hidden]="!labService.selectedLab()?.logo"
                             alt="" class="border border-2 rounded-1"
                             height="40"/>
                        <h4 class="fw-bold m-0">
                            {{ labService.selectedLab()?.name }}
                        </h4>
                    </div>
                    <div *ngIf="labService.selectedLab()?.rating" class="d-flex gap-2 align-items-baseline">
                        <span class="number">{{ labService.selectedLab()?.rating?.toFixed(1) }}</span>
                        <div>
                            @for (e of [].constructor(5); let i = $index; track i) {
                                @if ((labService.selectedLab()?.rating ?? 0) > i) {
                                    <i class="fa text-warning"
                                       [class.fa-star]="(labService.selectedLab()?.rating ?? 0) >= i + .75"
                                       [class.fa-star-half]="(labService.selectedLab()?.rating ?? 0) < i + .75">
                                    </i>
                                }
                            }
                        </div>
                        ({{ labService.selectedLab()?.ratingCount }})
                    </div>

                    <span class="align-self-center align-self-lg-start">
                        {{ labService.selectedLab()?.address }}
                    </span>
                </div>
                <div class="col-lg-4 col-12 d-flex flex-column gap-4 px-5 pt-1">
                    <div class="w-100 d-flex flex-column gap-3">
                        <h3 class="fw-bold text-center">{{ 'date_selection_title' | translate }}</h3>
                        <mat-form-field subscriptSizing="dynamic">
                            <input [min]="currentDate" (click)="onInputClick(); $event.preventDefault()" matInput
                                   (keydown)="$event.preventDefault()"
                                   placeholder="{{'appointment_booking_date' | translate}}"
                                   [matDatepicker]="picker" [(ngModel)]="date"
                                   (ngModelChange)="onChanged()">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker [calendarHeaderComponent]="datepickerHeader"
                                            panelClass="custom-picker"></mat-datepicker>
                        </mat-form-field>
                        <!--                        <div class="p-2 border-2 border rounded-4">-->
                        <!--                            <mat-calendar lang="el" (selectedChange)="onChanged()"-->
                        <!--                                          [(selected)]="date"></mat-calendar>-->
                        <!--                        </div>-->
                    </div>


                    @if (timeBoxArray.length) {

                        <div #timeBoxContainer>
                            <h3 class="fw-bold text-center">{{ 'time_slot_selection_title' | translate }}</h3>
                            <div class="row">
                                @for (box of timeBoxArray; track box.time) {
                                    <div class="col-4 col-md-3 p-2 d-flex">
                                        <button class="border-0 flex-fill text-dark time-box"
                                                matTooltip="{{ box.onFire && box.available ? ('time_slot_on_fire_info' | translate) : null }}"
                                                matTooltipPosition="above"
                                                [disabled]="!box.available"
                                                [ngClass]="{'selected': selectedTime === box.time, 'disabled': !box.available}"
                                                (click)="selectBox(box.time)">
                                            {{ box.displayTime }}
                                            @if (box.onFire) {
<!--                                                <i [class.border]="selectedTime === box.time"-->
<!--                                                   [class.border-2]="selectedTime === box.time"-->
<!--                                                   [class.border-diagnosou-secondary]="selectedTime === box.time"-->
<!--                                                   class="low-cap-icon fa-fire fa-regular text-diagnosou-accent pulsate rounded-circle">-->
<!--                                                </i>-->
                                                <i class="fa-regular fa-fire mb-1 ms-1"></i>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    } @else if (showEmptySlotsMessage) {
                        <div class="alert d-flex align-items-center alert-warning gap-2 m-0">
                            <i class="fa fa-exclamation-circle alert-heading"></i>
                            <p class="m-0">{{ 'no_time_slots' | translate }}</p>
                        </div>
                    }
                    <div class="alert d-flex align-items-center alert-purple gap-2 m-0">
                        <i class="fa fa-exclamation-circle alert-heading"></i>
                        <p class="m-0">{{ "scheduling_time_info" | translate }}</p>
                    </div>
                </div>
            </div>
        } @else {
            <div class="alert alert-info d-flex align-items-center alert-purple gap-2">
                <i class="fa fa-exclamation-circle alert-heading"></i>
                <p class="m-0">Φαίνεται ότι δεν έχετε
                    επιλέξει {{ labService.selectedTests()?.length ? 'εξεταστικό κέντρο' : 'τεστ' }}.
                    Παρακαλούμε
                    <a href="/{{ labService.selectedTests()?.length ? 'lab' : 'test' }}-select"
                       class="text-diagnosou-primary fw-bold mx-1">
                        επιλέξτε {{ labService.selectedTests()?.length ? 'εξεταστικό κέντρο' : 'τεστ' }}
                    </a>
                    για να συνεχίσετε
                </p>
            </div>
        }

    </div>

    <div class="d-flex align-items-center m-md-5 m-3">
        <div class="d-none flex-fill d-md-block">
            @if (!rescheduling()) {
                <button class="btn btn-secondary back-btn fs-6 fw-bold w-auto d-md-block d-none"
                        [disabled]="loading()"
                        (click)="nav.goBack()">
                    {{ 'generic_back_btn'|translate }}
                </button>
            }
        </div>
        <button [disabled]="!selectedTime || !selectedDate() || !labService.selectedLab() || loading()"
                class="btn btn-gradient flex-grow-1 flex-md-grow-0"
                (click)="bookAppointment()">
            {{ (!rescheduling() ? 'scheduling_action_button_title' : 'booking_reschedule_button') | translate }}
        </button>
    </div>
</div>

