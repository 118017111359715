import {Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {LabSelectComponent} from './pages/lab-select/lab-select.component';
import {MobileVerificationComponent} from './pages/mobile-verification/mobile-verification.component';
import {EligibilityComponent} from './pages/eligibility/eligibility.component';
import {TermsComponent} from './pages/terms/terms.component';
import {SmsConfirmationComponent} from './pages/sms-confirmation/sms-confirmation.component';
import {AppointmentComponent} from './pages/appointment/appointment.component';
import {AuthGuard} from "./core/guards/auth.guard";
import {RescheduleComponent} from "./pages/reschedule/reschedule.component";
import {TestSelectionComponent} from "./pages/test-selection/test-selection.component";
import {AppointmentsComponent} from "./pages/appointments/appointments.component";
import {ExistingAppointmentComponent} from "./pages/existing-appointment/existing-appointment.component";
import {SsnBookComponent} from "./pages/ssn-book/ssn-book.component";
import {ContactComponent} from "./pages/contact/contact.component";
import {GdprComponent} from "./pages/gdpr/gdpr.component";

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent

    },
    // Main flow
    {
        path: 'test-select',
        component: TestSelectionComponent,
        data: {
            title: "exams_list_screen_title",
        }

    },
    {
        path: 'lab-select',
        component: LabSelectComponent,
        data: {
            title: "lab_details_screen_title",
            subtitle: "lab_details_screen_subtitle"
        }

    },
    {
        path: 'mobile-verification',
        component: MobileVerificationComponent,
        data: {
            title: "login_screen_title",

        }
    },
    {
        path: 'eligibility',
        component: EligibilityComponent,
        data: {
            title: "generic_eligibility",

        },
        canActivate: [AuthGuard],
    },
    {
        path: 'terms',
        component: GdprComponent,
        data: {
            title: "gdpr_terms_title",

        }

    },
    {
        path: 'sms-confirmation',
        component: SmsConfirmationComponent,
        data: {
            // title: "Επαλήθευση",

        },
        canActivate: [AuthGuard],
    },
    {
        path: 'existing-appointment',
        component: ExistingAppointmentComponent,
        data: {
            // title: "Επαλήθευση",

        },
        canActivate: [AuthGuard],

    },
    {
        path: 'appointment',
        component: AppointmentComponent,
        data: {
            title: "scheduling_screen_title",

        },
        canActivate: [AuthGuard],
    },
    // Main flow end
    {
        path: 'appointments',
        component: AppointmentsComponent,
        data: {
            title: "bookings_list_screen_title",
            showNav: true
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'tos',
        component: TermsComponent,
        data: {
            title: "gdpr_terms_title",
            hideCookiebot: true,
            hideBanner: true
        }

    },
    {
        path: 'gdpr',
        component: GdprComponent,
        data: {
            title: "gdpr_terms_title",
            hideCookiebot: true,
            hideBanner: true
        }

    },
    {
        path: 'contact',
        component: ContactComponent,
        data: {
            title: "contact_us_screen_title",
            showNav: true
        }

    },
    {
        path: 'r/:hash',
        component: RescheduleComponent,
    },
    {
        path: 'e/:hash',
        component: SsnBookComponent,
    },
    {
        path: '**',
        redirectTo: ''
    }
];
