<div class="main-container" id="main-container" >
    <app-navigation-bar [hidden]="appLocal.navBarHidden()"
                        [isRoot]="true"
                        #navBar
                        [currentStage]="-1">
    </app-navigation-bar>
    <div class="main" id="main" #main>
        <router-outlet></router-outlet>
    </div>
</div>
