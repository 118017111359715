import { Component, Input, input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-revolab-button',
  standalone: true,
  imports: [NgbModule, CommonModule],
  templateUrl: './revolab-button.component.html',
  styleUrl: './revolab-button.component.scss'
})
export class RevolabButtonComponent{
    @Input() buttonText: string = '';
    @Input() width: string = 'auto';
    @Input() disabled?: boolean = false;


}
