<div class="modal-wrapper" [hidden]="hidden" #wrapper>
    @if (open()) {
        <div class="modal-backdrop" @fade (click)="closeWithBackdrop && hide()" [ngClass]="backdropClass"
             #backdrop></div>
        <div #modal @fade class="modal-contents d-flex flex-column" [ngClass]="modalClass" [class.modal-full]="full">
            @if (hasHeader) {
                <div #header
                     class="d-flex gap-3 justify-content-between align-items-center w-100 p-3 border-bottom"
                     [ngClass]="headerClass">
                    <div #headerContent class="flex-fill" [hidden]="!headerContent?.childNodes?.length">
                        <ng-content select="[header]"></ng-content>
                    </div>
                    @if (!headerContent?.childNodes?.length) {
                        <h4 class="flex-fill m-0">{{ headerTitle }}</h4>
                    }
                    @if (hasCloseButton) {
                        <i class="fa fa-times fa-2x text-diagnosou-primary cursor-pointer" (click)="hide()"></i>
                    }
                </div>
            }
            <div #body class="flex-fill overflow-auto" [class.p-3]="hasPadding" [ngClass]="bodyClass">
                <ng-content select="[body]"></ng-content>
            </div>
            @if (hasFooter) {
                <div #footer class="p-3 w-100 border-top" [ngClass]="footerClass">
                    <ng-content select="[footer]"></ng-content>
                </div>
            }
        </div>
    }
</div>
