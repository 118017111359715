<div class="d-flex h-100 flex-column gap-3">
    <app-navigation-bar [currentStage]="-1" [showBackBtn]="false"></app-navigation-bar>
    <div class="container overflow-auto d-flex flex-column align-items-center justify-content-center flex-fill gap-5">
        <div class="position-relative w-100" [ngStyle]="{height: '30svh'}">
            <img ngSrc="/assets/images/existing-appointment.svg" fill
                 alt="sms confirmation" class="object-fit-contain">
        </div>
        <div class="text-center">
            <h3 class="fw-bold">{{'already_appointment_screen_message' | translate}}</h3>
            <p>{{'already_appointment_screen_prompt' | translate}}</p>
        </div>
    </div>
    <button (click)="viewAppointment()" class="btn btn-gradient align-self-md-end mx-3 mb-3 m-md-5">
        {{ 'already_appointment_action_button' | translate }}
    </button>
</div>
