<app-revolab-promo-header [device]="!isMobile ? 'desktop' : 'mobile'"
                          [ngClass]="{
                            'sticky': isSticky && isVisible,
                            'visible': isVisible,
                            'hidden': !isVisible,
                            'events-none': !isVisible
                          }"
                          class="d-flex justify-content-center">
</app-revolab-promo-header>

<div id="section-logo">
    <div class="w-100 px-3 d-flex p-3 justify-content-between">
        <div class="mt-md-3 mt-5 ps-5 ms-3">
            @if (isMobile) {
                <img ngSrc="/assets/images/Logo%20black.svg"
                     height="{{40 * .75}}" width="{{286 * .75}}"
                     priority alt="diagnosou-logo">
            } @else {
                <img ngSrc="/assets/images/Logo%20black.svg"
                     height="40" width="286"
                     priority alt="diagnosou-logo">
            }
        </div>
        <app-account-menu [dark]="true"></app-account-menu>
    </div>
</div>

<div id="section0" #section0>
    <div class="row">
        <div class="col-lg-6 col-12 d-flex flex-column align-items-center">
            @if (!lang() || lang() === 'el') {
                @if (isMobile) {
                    <img ngSrc="/assets/images/Main%20Greek.jpg" height="512" width="512"
                         alt="couple" class="img-fluid" priority>
                } @else {
                    <img ngSrc="/assets/images/Main%20Greek.jpg" height="1024" width="1024"
                         alt="intro" class="img-fluid" priority>
                }
            } @else {
                @if (isMobile) {
                    <img ngSrc="/assets/images/Main%20English.jpg" height="512" width="512"
                         alt="couple" class="img-fluid" priority>
                } @else {
                    <img ngSrc="/assets/images/Main%20English.jpg" height="1024" width="1024"
                         alt="intro" class="img-fluid" priority>
                }
            }

        </div>
        <div class="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center">
            <div class="wrapper custom-container" style="margin-bottom: 2rem;">
                <div class="title mt-3">{{ 'intro_opening_title' | translate }}</div>
                <div class="text mb-4">
                    {{ 'intro_opening_subtitle' | translate }}
                </div>
                <a class="revolab-button" href="/test-select">
                    <app-revolab-button [width]="'333px'"
                                        [buttonText]="'intro_action_button_title'|translate">
                    </app-revolab-button>
                </a>
            </div>
        </div>
    </div>
</div>
<div id="section1">
    <div class="row">
        <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center">
            <div class="wrapper custom-container">
                <div class="title mb-5">
                    {{'intro_card_hpv_percentage' | translate}}
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12">
            <img ngSrc="/assets/images/1 - 1024.jpg" alt="couple" height="1024" width="1024" class="img-fluid">
        </div>
    </div>
</div>

<div id="section2">
    <div class="row">
        <div class="col-lg-6 col-12">
            <div class="text-center">
                <img ngSrc="/assets/images/2 - 1024.png" height="1176" width="1166" alt="couple2" class="img-fluid">
            </div>
        </div>
        <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center">
            <div class="wrapper custom-container">
                <div class="title mt-5">
                    {{ 'intro_card_hpv_common_title' | translate }}
                </div>
                <div class="text">
                    {{ 'intro_card_hpv_common_subtitle' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>

<div id="section3">
    <div class="wrapper custom-container d-flex flex-column align-items-center text-center">
        <div class="woman-text">
            {{'intro_women_title' | translate}}
        </div>
        <div class="title">
            {{ 'intro_women_subtitle' | translate }}
        </div>
        <div class="text">
            {{'intro_women_footnote' | translate}}
        </div>
    </div>
</div>

<div id="section4" class="flex-column">
    <div class="row">
        <div class="huge-title col-12">{{ 'intro_card_prevention_steps_title' | translate }}</div>
    </div>
    <div class="row">
        <div class="col-lg-4 col-12">
            <img ngSrc="/assets/images/3 - 1024.jpg" height="867" width="1024" alt="" class="img-fluid">
            <div class="title text-center mt-0">{{ 'intro_card_prevention_screening' | translate }}</div>
        </div>
        <div class="col-lg-4 col-12">
            <img ngSrc="/assets/images/4 - 1024.jpg" height="867" width="1024" alt="" class="img-fluid">
            <div class="title text-center mt-0">{{ 'intro_card_prevention_vaccination' | translate }}</div>
        </div>
        <div class="col-lg-4 col-12">
            <img ngSrc="/assets/images/5 - 1024.jpg" height="867" width="1024" alt="" class="img-fluid">
            <div class="title text-center mt-0">{{ 'intro_card_prevention_treatment' | translate }}</div>
        </div>
    </div>

</div>

<div id="section5">
    <div class="row">
        <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center">
            <div class="wrapper custom-container">
                <div class="title">
                    {{ 'intro_card_hpv_types_title' | translate }}
                </div>
                <div class="text">{{ 'intro_card_hpv_types_subtitle' |translate }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-12">
            <img ngSrc="/assets/images/6 - 1024 2.png" height="2504" width="2492" alt="virus" class="img-fluid">
        </div>
    </div>
</div>

<div id="section6" class="flex-column">
    <div class="row wrapper custom-container">
        <div class="col-12">
            <div class="huge-title">{{ 'intro_control_title' | translate }}</div>
            <div class="title">{{'intro_control_subtitle' | translate}}</div>
        </div>
    </div>

    <div class="box row" id="pap-test">
        <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center">
            <div class="wrapper">
                <div class="test-title" style="color: #2B35B0;">{{'intro_card_pap_test_title' | translate}}</div>
                <div class="test-subtitle" style="color: #2B35B0">
                    {{'intro_card_pap_test_subtitle' | translate}}
                </div>
                <div *ngIf="!isMobile" class="text" style="color: #2B35B0">
                    {{'intro_card_pap_test_footnote' | translate}}
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12">
            <img src="/assets/images/7 - 1024.jpg" alt="" class="img-fluid">
        </div>
        <div *ngIf="isMobile" class="text col-12 custom-container" style="color: #2B35B0">
            {{'intro_card_pap_test_footnote' | translate}}
        </div>

    </div>
    <div class="box row" id="hpv-test">
        <div class="text col-12 custom-container" *ngIf="isMobile" style="color:#9633A9;">
            {{ 'intro_card_hpv_test_caption' | translate }}
        </div>
        <div class="col-lg-6 col-12">
            <img ngSrc="/assets/images/8 - 1024.jpg" height="1024" width="1024" alt="" class="img-fluid">
        </div>
        <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center">
            <div class="wrapper">
                <div class="test-title" style="color:#9633A9;">{{ 'intro_card_hpv_test_title' | translate }}</div>
                <div class="test-subtitle" style="color:#9633A9;">
                    {{ 'intro_card_hpv_test_subtitle' | translate }}
                </div>
                <div class="text" *ngIf="!isMobile" style="color:#9633A9;">
                    {{ 'intro_card_hpv_test_caption' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div style="width: 75vw;">
        <div class="depending-text">
            {{'intro_age_dependant' | translate}}
        </div>
    </div>
</div>

<div id="section7">
    <div class="row">
        <div class="col-lg-6 col-12  d-flex justify-content-center align-items-center">
            <div class="wrapper custom-container">
                <div class="huge-title">{{ 'intro_card_prevention_title' | translate }}</div>
                <div class="text">
                    {{ 'intro_card_prevention_subtitle' | translate }}
                </div>
                <div class="message-container">
                    <div class="message" [class.message-mobile]="isMobile">
                        <span class="fw-bold text-center text-white"
                              [class.fs-3]="!isMobile"
                              [class.fs-6]="isMobile">
                            {{'intro_card_prevention_caption' | translate}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12">
            <img ngSrc="/assets/images/9%20-%201024.png" height="1024" width="1024" alt="doctor" class="img-fluid">
        </div>
        <div *ngIf="isMobile" class="col-12">
            <div class="sponsor-title">{{'gov_sponsored_program' | translate}}</div>
        </div>

        @if (!isMobile) {
            <div class="back-to-top mt-3 cursor-pointer" (click)="scrollToTop()">
                <i class="fa fa-arrow-up"></i>
                {{ 'back_to_top' | translate}}
            </div>
        }

    </div>
</div>

<app-modal #pendingActionsModal
           [hasHeader]="false"
           bodyClass="position-relative pb-0"
           [hasFooter]="false">
    <ng-container body>
        <app-pending-actions (hasPendingActions)="$event ? pendingActionsModal.show() : pendingActionsModal.hide()">
        </app-pending-actions>
    </ng-container>
</app-modal>
