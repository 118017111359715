import {Component, forwardRef, Input} from '@angular/core';
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";
import {AsyncPipe, JsonPipe, NgClass} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-terms-checkbox',
    standalone: true,
    imports: [
        MatCheckbox,
        FormsModule,
        NgClass,
        JsonPipe,
        AsyncPipe,
        TranslateModule
    ],
    templateUrl: './terms-checkbox.component.html',
    styleUrl: './terms-checkbox.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TermsCheckboxComponent),
            multi: true
        }
    ]
})
export class TermsCheckboxComponent implements ControlValueAccessor {

    @Input() class: string = '';
    @Input() redirectQuery: string = '';

    checked = false;

    private onChange = (_value: boolean) => {};
    private onTouched = () => {};

    constructor(public route: ActivatedRoute) {
    }

    writeValue(value: boolean): void {
        this.checked = value;
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
}

    setDisabledState?(isDisabled: boolean): void {
        // Handle the disabled state if needed
    }

    toggleChecked(): void {
        this.checked = !this.checked;
        this.onChange(this.checked);
        this.onTouched();
    }
}
