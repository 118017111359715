import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LabService} from "../../core/services/lab.service";
import {LocalStorageService, SessionStorageService} from "ngx-webstorage";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {CommonModule} from "@angular/common";
import {LoaderComponent} from "../../core/components/loader/loader.component";

@Component({
    selector: 'app-ssn-book',
    standalone: true,
    imports: [
        CommonModule,
        LoaderComponent
    ],
    templateUrl: './ssn-book.component.html',
    styleUrl: './ssn-book.component.scss'
})
export class SsnBookComponent {

    private authenticated = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private labService: LabService,
        private session: SessionStorageService,
        private localStorage: LocalStorageService,
    ) {
        this.route.params.pipe(takeUntilDestroyed()).subscribe(params => {
            // Since most of the time the installation id is not available on startup, we need to wait for a bit before we can verify the hash
            setTimeout(() => this._verifyHash(params['hash']), 500);
        });

        this.authenticated = !!localStorage.retrieve("session_token");
    }

    private _verifyHash(hash: string) {
        if (!hash) {
            this.router.navigate([
                this.authenticated
                    ? '/appointments'
                    : '/'
            ]);
            return;
        }

        this.labService.retrieveSsnReqByHash(hash)
            .pipe(catchError((e) => {
                return of({success: false, subCode: e.error?.subCode, message: e.error?.message});
            }))
            .subscribe((res) => {
                if (!res.success) {
                    this.router.navigate([
                        this.authenticated
                            ? '/appointments'
                            : '/'
                    ]);
                    return;
                }

                this.localStorage.store("session_token", res.userSessionToken);
                this.labService.selectedLab.set(res.lab && {
                    id: res.lab.id,
                    name: res.lab.displayName,
                    address: res.lab.address,
                });
                this.labService.selectedTests.set(res.exams?.map((test: string) => ({id: test})));
                this.labService.rescheduleId.set(null);
                this.labService.rescheduleReasonId.set(null);
                this.session.store("privateAppointment", !!res.privately);
                this.session.store("ssnReqId", res.ssnRequestId);
                this.router.navigate(['/appointment']);
            });


    }
}
