import {Component, OnInit} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MapComponent} from "../../core/components/map/map.component";
import {CommonModule} from '@angular/common';
import {NavigationBarComponent} from "../../core/components/navigation-bar/navigation-bar.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-lab-select',
    standalone: true,
    imports: [NgbModule, MapComponent, CommonModule, NavigationBarComponent, TranslateModule],
    templateUrl: './lab-select.component.html',
    styleUrl: './lab-select.component.scss'
})
export class LabSelectComponent implements OnInit {

    constructor() {
    }

    currentStage = 0;
    labs: any[] = [];

    ngOnInit(): void {
    }

    onCurrentStageChange(newStage: number) {
        this.currentStage = newStage; // Update the currentStage based on the navigation bar input
    }
}
