import {Injectable, Renderer2, RendererFactory2, signal} from '@angular/core';
import {Router, NavigationEnd, ActivationEnd} from '@angular/router';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter, map} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _lastUrl = signal<string | null>(null);
    private _currentUrl = signal<string | null>(null);
    private _skipNextUrlUpdate = false;

    private renderer: Renderer2;

    constructor(private router: Router, private rf: RendererFactory2) {
        this.renderer = this.rf.createRenderer(null, null);
        this.router.events.pipe(
            takeUntilDestroyed(),
            filter(event => event instanceof NavigationEnd),
            map(event => event as NavigationEnd)
        ).subscribe((event) => {
            if (this._skipNextUrlUpdate) {
                this._skipNextUrlUpdate = false;
                return;
            }
            this._lastUrl.set(this._currentUrl());
            this._currentUrl.set(event.urlAfterRedirects);
        });
        this.router.events.pipe(
            takeUntilDestroyed(),
            filter(event => event instanceof ActivationEnd),
            map(event => event as ActivationEnd)
        ).subscribe((event) => {
            this._handleCookiebotVisibility(event?.snapshot?.data?.['hideCookiebot']);
            this._handleSmartBannerVisibility(event?.snapshot?.data?.['hideBanner'])
        });

    }

    public goBack() {
        this.router.navigate([this._lastUrl() ?? '/']);
    }

    public updateLastUrl(url: string | null) {
        this._lastUrl.set(url);
    }

    public updateCurrentUrl(url: string | null) {
        this._currentUrl.set(url);
    }

    public set skipNextUrlUpdate(value: boolean) {
        this._skipNextUrlUpdate = value;
    }

    public get lastUrl() {
        return this._lastUrl.asReadonly();
    }

    public get currentUrl() {
        return this._currentUrl.asReadonly();
    }

    private _handleSmartBannerVisibility(hide: boolean) {
        if (!hide) {
            return;
        }

        const banner = document.querySelector("nc-ngx-smart-banner");
        if (banner) {
            this.renderer.removeChild(document.body, banner);
        }
    }

    private _handleCookiebotVisibility(hide: boolean) {
        try {
            let script: any = document?.getElementById('Cookiebot');
            if (hide) {
                if (script) {
                    this.renderer.removeChild(document.head, script);
                }
                return;
            }

            if (script) {
                // we do not want to add it multiple times
                return;
            }

            script = this.renderer.createElement('script');
            script.id = 'Cookiebot';
            script.type = 'text/javascript';
            script.src= "https://consent.cookiebot.com/uc.js"
            this.renderer.setAttribute(script, 'data-cbid', 'b742feb7-6487-4ad8-a990-75cce4e8e7ed');
            this.renderer.setAttribute(script, 'data-blockingmode', 'auto');
            this.renderer.appendChild(document.head, script);
        } catch (e) {
            console.error(`Error trying to ${hide ? 'remove' : 'add'} Cookiebot script`, e);
        }
    }
}
