import {Component} from '@angular/core';
import {NavigationBarComponent} from "../../core/components/navigation-bar/navigation-bar.component";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
    selector: 'app-existing-appointment',
    standalone: true,
    imports: [
        CommonModule,
        NavigationBarComponent,
        NgOptimizedImage,
        TranslateModule
    ],
    templateUrl: './existing-appointment.component.html',
    styleUrl: './existing-appointment.component.scss'
})
export class ExistingAppointmentComponent {

    constructor(
        private router: Router,
    ) {

    }

    viewAppointment() {
        this.router.navigate(['/appointments'], {queryParams: {view: 'active'}});
    }
}
