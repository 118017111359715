import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    LOCALE_ID,
    provideZoneChangeDetection
} from '@angular/core';
import {provideRouter, Router} from '@angular/router';
import localeEl from '@angular/common/locales/el';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideToastr} from "ngx-toastr";
import {
    LocalStorageService,
    provideNgxWebstorage,
    withLocalStorage,
    withNgxWebstorageConfig,
    withSessionStorage
} from "ngx-webstorage";
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withFetch,
    withInterceptorsFromDi
} from "@angular/common/http";
import {HttpRequestsInterceptor} from "./core/interceptors/http-requests.interceptor";
import {registerLocaleData} from "@angular/common";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslationLoader} from "./core/classes/TranslationLoader";
import {provideMomentDateAdapter} from "@angular/material-moment-adapter";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import * as Sentry from '@sentry/angular';

registerLocaleData(localeEl, 'el');
export function LocaleFactory(ls: LocalStorageService): TranslateLoader {
    return ls.retrieve('preferred_lang') || 'el';
}

export function HttpLoaderFactory(http: HttpClient, ls: LocalStorageService): TranslateLoader {
    return new TranslationLoader(http, ls, {cache: true});
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideRouter(routes),
        {provide: LOCALE_ID, useFactory: LocaleFactory, deps: [LocalStorageService]},
        {provide: MAT_DATE_LOCALE, useFactory: LocaleFactory, deps: [LocalStorageService]},
        // provideClientHydration(), // TODO enable this if we re enable SSR
        provideAnimationsAsync(),
        provideMomentDateAdapter(),
        provideToastr(),
        provideNgxWebstorage(
            withNgxWebstorageConfig({separator: '', caseSensitive: true, prefix: ''}),
            withLocalStorage(),
            withSessionStorage()
        ),
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        {provide: HTTP_INTERCEPTORS, useClass: HttpRequestsInterceptor, multi: true},
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient, LocalStorageService]
                }
            })
        ),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ]
};
