<div class="h-100 d-flex flex-column">
    @if (!isExternal) {
        <app-navigation-bar #nav [currentStage]="currentStage"
                            (currentStageChange)="onCurrentStageChange($event)"></app-navigation-bar>

        <ng-container *ngTemplateOutlet="ToS"></ng-container>

        <div class="m-3 mt-0 m-md-5 d-flex align-items-center">
            <div class="d-none flex-fill d-md-block">
                <button class="btn btn-secondary back-btn fs-6 fw-bold w-auto d-md-block d-none"
                        (click)="nav.goBack()">
                    {{ 'generic_back_btn'|translate }}
                </button>
            </div>
            <button class="position-sticky btn btn-gradient flex-grow-1 flex-md-grow-0" (click)="close()">
                {{ 'generic_button_submit' | translate }}
            </button>

        </div>
    } @else {
        <ng-container *ngTemplateOutlet="ToS"></ng-container>
    }
</div>

<ng-template #ToS>
    <ul class="list-unstyled pt-3 px-4 container position-relative d-flex flex-fill overflow-auto flex-column gap-3">
        @switch (lang()) {
            @case('el') {
                <li>
                    <h5 class="fw-bold">ΝΟΜΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ</h5>
                    <p>Ο Ιστότοπος <a target="_blank" href="http://diagnosou.com">www.diagnosou.com</a>, <a target="_blank" href="http://diagnosou.gr">www.diagnosou.gr</a> συντάσσεται, φιλοξενείται και ανήκει στην κυριότητα της:</p>
                    <p>DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε.<br>
                        Κλεισθένους 39-41,<br>
                        15343, Αττική, Ελλάδα<br>
                        Greece</p>
                    <p>Αρ. ΓΕΜΗ: 179967301000<br>
                        Telephone: [.]<br>
                        Email: <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a></p>
                </li>
                <li>
                    <h5 class="fw-bold">ΕΠΙΣΚΟΠΗΣΗ</h5>
                    <p>www.diagnosou.com (εφεξής ο «Ιστότοπος») είναι ένας εμπορικός ιστότοπος για την συνοπτική παρουσίαση των δραστηριοτήτων, των προϊόντων και των υπηρεσιών που προσφέρουν η DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε. (ή DIAGNOSOU), η οποία εδρεύει στη διεύθυνση Κλεισθένους 39-4, 15343, Αγία Παρασκευή Αττικής, Ελλάδα και οι θυγατρικές της.</p>
                    <p>Ο Ιστότοπος διατίθεται δωρεάν σε κάθε χρήστη με πρόσβαση στο διαδίκτυο. Όλες οι δαπάνες που σχετίζονται με την πρόσβαση και τη χρήση του Ιστότοπου, είτε πρόκειται για κόστος μηχανημάτων (hardware), λογισμικού (software) ή πρόσβασης στο Διαδίκτυο, βαρύνουν αποκλειστικά τον χρήστη.</p>
                </li>
                <li>
                    <h5 class="fw-bold">ΑΠΟΔΟΧΗ ΚΑΙ ΤΡΟΠΟΠΟΙΗΣΗ ΤΩΝ ΟΡΩΝ ΧΡΗΣΗΣ</h5>
                    <p>Η πρόσβαση και η χρήση του Ιστότοπου υπόκειται στην αποδοχή και τη συμμόρφωση με τους όρους και τις προϋποθέσεις χρήσης («Όροι χρήσης») που παρατίθενται στο παρόν. Με την πλοήγηση και/ή τη χρήση του παρόντος Ιστότοπου, ο χρήστης θεωρείται ότι έχει διαβάσει τους παρόντες Όρους Χρήσης, ότι τους έχει κατανοήσει πλήρως και ότι αποδέχεται πλήρως όλες τις διατάξεις τους χωρίς περιορισμούς ή επιφυλάξεις. Εάν ο χρήστης δεν συμφωνεί με τους παρόντες Όρους Χρήσης, οφείλει να σταματήσει να χρησιμοποιεί τον Ιστότοπο.</p>
                    <p>Η DIAGNOSOU διατηρεί το δικαίωμα να τροποποιεί τους παρόντες Όρους Χρήσης ανά πάσα στιγμή και χωρίς προειδοποίηση. Για να ενημερωθεί για τυχόν αλλαγές, ο χρήστης οφείλει να ανατρέξει πριν από κάθε χρήση του Ιστότοπου στην τελευταία έκδοση των Όρων Χρήσης, η οποία είναι προσβάσιμη ανά πάσα στιγμή στην Ιστοσελίδα. Η χρήση του Ιστότοπου υπόκειται στους Όρους Χρήσης που ισχύουν κατά τη στιγμή της χρήσης.</p>
                    <p>Οποιαδήποτε διάταξη των παρόντων Όρων Χρήσης απαγορεύεται ή είναι παράνομη ή ανεφάρμοστη βάσει οποιουδήποτε εφαρμοστέου νόμου, ο οποίος επιβάλλεται από οποιοδήποτε δικαστήριο αρμόδιας δικαιοδοσίας, θα πρέπει, στο βαθμό που απαιτείται από τον εν λόγω νόμο, να διαχωρίζεται από τους παρόντες Όρους Χρήσης και να καθίσταται ανίσχυρη στο μέτρο του δυνατού χωρίς να τροποποιούνται οι υπόλοιπες διατάξεις. Η εν όλω ή εν μέρει ακυρότητα οποιασδήποτε διάταξης των παρόντων Όρων Χρήσης δεν ακυρώνει ούτε επηρεάζει την εγκυρότητα οποιασδήποτε άλλης διάταξης του παρόντος, εκτός εάν η εν λόγω διάταξη αλλοιώνει το γενικό νόημα του συνόλου των διατάξεων των παρόντων Όρων Χρήσης.</p>
                </li>
                <li>
                    <h5 class="fw-bold">ΤΕΧΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ – ΔΙΑΘΕΣΙΜΟΤΗΤΑ</h5>
                    <p>Ο Ιστότοπος είναι διαθέσιμος 24 ώρες την ημέρα, 7 ημέρες την εβδομάδα, συμπεριλαμβανομένων των Κυριακών και των εθνικών αργιών, με την επιφύλαξη τυχόν γεγονότων ανωτέρας βίας ή οποιουδήποτε γεγονότος που δεν ελέγχεται από την DIAGNOSOU και με την επιφύλαξη περιόδων συντήρησης. Η DIAGNOSOU δεσμεύεται μόνο από την υποχρέωση παροχής μέσων για τη διαθεσιμότητα της Ιστοσελίδας. Η DIAGNOSOU διατηρεί το δικαίωμα να διακόψει, να αναστείλει ή να περιορίσει την πρόσβαση στο σύνολο ή σε μέρος της Ιστοσελίδας, ιδίως λόγω νομικών ή τεχνικών περιορισμών. Οι χρήστες αναγνωρίζουν ρητά ότι οι προαναφερθείσες περίοδοι συντήρησης και τυχόν διακοπές, αναστολές ή περιορισμοί μπορούν να συμβούν ανά πάσα στιγμή χωρίς προηγούμενη ειδοποίηση.</p>
                    <p>Με τη χρήση του Ιστοτόπου οι χρήστες, αναγνωρίζουν, συμφωνούν και αποδέχονται ότι η πρόσβαση στον ιστότοπο περιορίζεται στα χαρακτηριστικά και τα όρια του Διαδικτύου, ιδίως όσον αφορά τις τεχνικές επιδόσεις, τους χρόνους ανταπόκρισης, τους κινδύνους διακοπής και γενικότερα τους κινδύνους που ενέχει κάθε σύνδεση στο Διαδίκτυο, επί των οποίων η DIAGNOSOU δεν έχει κανέναν έλεγχο. Ως εκ τούτου, συνιστά αποκλειστική ευθύνη των χρηστών να λαμβάνουν όλα τα κατάλληλα μέτρα για την προστασία των δικών τους δεδομένων ή/και του λογισμικού που είναι αποθηκευμένα στην υποστήριξη του υπολογιστή τους από οποιαδήποτε παραβίαση.</p>
                    <p>ΟΠΟΙΑΔΗΠΟΤΕ ΧΡΗΣΗ ΤΟΥ ΙΣΤΟΤΟΠΟΥ ΓΙΝΕΤΑΙ ΜΕ ΕΥΘΥΝΗ ΤΟΥ ΧΡΗΣΤΗ ΚΑΙ Ο ΧΡΗΣΤΗΣ ΑΝΑΓΝΩΡΙΖΕΙ ΚΑΙ ΣΥΜΦΩΝΕΙ ΌΤΙ Ο ΙΣΤΟΤΟΠΟΣ ΠΑΡΕΧΕΤΑΙ ΓΙΑ ΧΡΗΣΗ «ΩΣ ΈΧΕΙ» ΧΩΡΙΣ ΚΑΜΙΑ ΑΠΟΛΥΤΩΣ ΕΓΓΥΗΣΗ. ΣΤΟ ΜΕΓΙΣΤΟ ΒΑΘΜΟ ΠΟΥ ΕΠΙΤΡΕΠΕΤΑΙ ΑΠΟ ΤΗΝ ΙΣΧΥΟΥΣΑ ΝΟΜΟΘΕΣΙΑ, Η DIAGNOSOU ΑΠΟΠΟΙΕΙΤΑΙ ΚΑΘΕ ΕΙΔΟΥΣ ΝΟΜΙΜΕΣ ΕΓΓΥΗΣΕΙΣ, ΕΙΤΕ ΡΗΤΕΣ, ΕΙΤΕ ΣΙΩΠΗΡΕΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ, ΧΩΡΙΣ ΠΕΡΙΟΡΙΣΜΟ, ΟΠΟΙΩΝΔΗΠΟΤΕ ΣΙΩΠΗΡΩΝ ΕΓΓΥΗΣΕΩΝ ΕΜΠΟΡΕΥΣΙΜΟΤΗΤΑΣ ΚΑΙ ΚΑΤΑΛΛΗΛΟΤΗΤΑΣ ΓΙΑ ΣΥΓΚΕΚΡΙΜΕΝΟ ΣΚΟΠΟ. Η DIAGNOSOU ΔΕΝ ΕΓΓΥΑΤΑΙ ΌΤΙ Ο ΔΙΚΤΥΑΚΟΣ ΤΟΠΟΣ Ή ΟΠΟΙΟΔΗΠΟΤΕ ΤΜΗΜΑ ΤΟΥ ΘΑ ΑΝΤΑΠΟΚΡΙΝΕΤΑΙ ΣΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΤΟΥ ΧΡΗΣΤΗ Ή ΌΤΙ ΘΑ ΕΙΝΑΙ ΑΔΙΑΛΕΙΠΤΩΣ ΑΣΦΑΛΗΣ Ή ΧΩΡΙΣ ΣΦΑΛΜΑΤΑ Ή ΌΤΙ ΤΑ ΣΦΑΛΜΑΤΑ ΘΑ ΔΙΟΡΘΩΝΟΝΤΑΙ.</p>
                </li>
                <li>
                    <h5 class="fw-bold">ΥΠΕΣΥΝΔΕΣΜΟΙ (HYPERLINKS)</h5>
                    <p>Όπου στον Ιστότοπο παρέχονται σύνδεσμοι σε άλλους ιστότοπους, οι παρόντες Όροι Χρήσης δεν καλύπτουν τον τρόπο και τους όρους χρήσης των ιστότοπων αυτών ή τον τρόπο με τον οποίο αυτός ο ιστότοπος επεξεργάζεται προσωπικά δεδομένα. Οι Χρήστες ενθαρρύνονται να διαβάσουν τους όρους χρήσης στους άλλους ιστότοπους που επισκέπτονται μέσα από την πλοήγηση στον εν λόγω Ιστότοπο.</p>
                    <p>Δεν ελέγχουμε τους ιστότοπους άλλων οργανισμών. Αυτοί οι ιστότοποι ελέγχονται από άλλους οργανισμούς ανεξάρτητους από τη DIAGNOSOU. Η DIAGNOSOU αποποιείται οποιασδήποτε άλλης εγγύησης σχετικά με τις ιστοσελίδες άλλων οργανισμών. Ο χρήστης αποδέχεται την αποκλειστική και αποκλειστική ευθύνη για τη χρήση των ιστοσελίδων άλλων οργανισμών.</p>
                </li>
                <li>
                    <h5 class="fw-bold">ΔΙΚΑΙΩΜΑ ΠΝΕΥΜΑΤΙΚΗΣ ΙΔΙΟΚΤΗΣΙΑΣ</h5>
                    <p>Ο Ιστότοπος και/ή όλα τα στοιχεία που περιέχονται σε αυτόν (ιδίως κείμενα, εικόνες, σήματα, λογότυπα, φωτογραφίες κ.λπ.) προστατεύονται από δικαιώματα πνευματικής ιδιοκτησίας (συμπεριλαμβανομένων ιδίως όλων των δικαιωμάτων πνευματικής ιδιοκτησίας, δικαιωμάτων ευρεσιτεχνίας, εμπορικών σημάτων, σχεδίων, μοντέλων, βάσεων δεδομένων, ονομάτων τομέα (domain names) και οποιωνδήποτε άλλων υφιστάμενων ή μελλοντικών δικαιωμάτων πνευματικής ιδιοκτησίας, ελληνικών και διεθνών) και ανήκουν στη DIAGNOSOU (και/ή στους συνεργάτες της στους οποίους κατά περίπτωση η DIAGNOSOU έχει μεταβιβάσει σχετικά δικαιώματα). Η χρήση του Ιστότοπου δεν ερμηνεύεται ως εκχώρηση ή μεταβίβαση στον χρήστη οποιουδήποτε ή όλων των δικαιωμάτων πνευματικής ιδιοκτησίας που σχετίζονται με τον Ιστότοπο και/ή όλα τα στοιχεία σε αυτόν, εκτός από ένα ελεύθερο και μη αποκλειστικό προσωπικό δικαίωμα πρόσβασης, που περιορίζεται αποκλειστικά στην επίσκεψη του Ιστότοπου.</p>
                    <p>Τα ονόματα, οι μάρκες και/ή τα λογότυπα “DIAGNOSOU” αποτελούν την πλήρη, απόλυτη και αποκλειστική ιδιοκτησία της DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε. (και/ή των συνεργατών της στους οποίους κατά περίπτωση η DIAGNOSOU έχει μεταβιβάσει σχετικά δικαιώματα). Οποιαδήποτε χρήση, αναπαράσταση, αναπαραγωγή και/ή εκμετάλλευση, εν όλω ή εν μέρει, αυτών των ονομάτων, εμπορικών σημάτων και/ή λογοτύπων, οποιασδήποτε φύσης, απαγορεύεται αυστηρά.</p>
                    <p>Οποιαδήποτε χρήση, αναπαράσταση, αναπαραγωγή και/ή εκμετάλλευση, εν όλω ή εν μέρει, του Ιστότοπου και/ή όλων των στοιχείων στον Ιστότοπο χωρίς την προηγούμενη γραπτή συναίνεση της DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε. συνιστά παραβίαση των δικαιωμάτων πνευματικής ιδιοκτησίας της DIAGNOSOU ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε. (και/ή των συνεργατών της στους οποίους κατά περίπτωση η DIAGNOSOU έχει μεταβιβάσει σχετικά δικαιώματα).</p>
                    <p>Σε αυτό το πλαίσιο, και χωρίς περιορισμούς, ο χρήστης δεσμεύεται να μην:</p>
                    <ul>
                        <li>Χρησιμοποιεί ή προσπαθεί να χρησιμοποιήσει τον Ιστότοπο και/ή όλα τα στοιχεία στον Ιστότοπο, εκτός όπως προβλέπεται στους παρόντες Όρους Χρήσης,</li>
                        <li>Αναπαράγει, αντιγράφει, μεταφέρει, μεταδίδει, συνοψίζει, ενσωματώνει, τροποποιεί, αλλάζει, μετακινεί, αφαιρεί, αντικαθιστά ή αναδιανέμει, μόνιμα ή προσωρινά, σε οποιοδήποτε μέσο ή με οποιονδήποτε τρόπο, ολόκληρο ή μέρος του Ιστότοπου, του περιεχομένου του, των συστατικών του ή των συστατικών του στοιχείων, χωρίς την προηγούμενη γραπτή άδεια της DIAGNOSOU,</li>
                        <li>Ζημιώνει ή προσπαθεί να ζημιώσει, άμεσα ή έμμεσα, τη φήμη ή την εικόνα της DIAGNOSOU, του Ιστότοπου και γενικά όλα τα δικαιώματα που ανήκουν στη DIAGNOSOU, με οποιονδήποτε τρόπο,</li>
                        <li>Αποκλείει ή προσπαθεί να αποκλείσει, διακόπτει ή προσπαθεί να διακόψει τη λειτουργία του Ιστότοπου</li>
                        <li>Κατεβάζει, διανέμει ή δημοσιεύει με οποιονδήποτε άλλο τρόπο, μέσω της χρήσης του Ιστότοπου, οποιοδήποτε περιεχόμενο, πληροφορία ή άλλο υλικό που θα μπορούσε (i) να παραβιάζει τα δικαιώματα οποιουδήποτε τρίτου μέρους· (ii) να είναι δυσφημιστικό, απειλητικό, συκοφαντικό, άσεμνο ή ανήθικο ή να μπορεί να επιφέρει οποιαδήποτε αστική ή ποινική ευθύνη βάσει οποιωνδήποτε νόμων ή κανονισμών· (iii) να περιλαμβάνει οποιουδήποτε είδους ιούς, σφάλματα στον κώδικα, επιθέσεις, οποιοδήποτε κακόβουλο λογισμικό (π.χ. Δούρειος Ίππος) ή άλλους επιβλαβείς κώδικες· (iv) να περιέχει πληροφορίες που θα μπορούσαν να χρησιμοποιηθούν για την ταυτοποίηση ενός ατόμου· ή (v) να παραβιάζει οποιουσδήποτε ισχύοντες νόμους και κανονισμούς,</li>
                        <li>Επιτρέπει, άμεσα ή έμμεσα, μέσω συμβουλής, βοήθειας ή υποστήριξης, σε οποιοδήποτε τρίτο μέρος να προβεί σε οποιαδήποτε από τις παραπάνω ενέργειες ή να παραβιάσει τα δικαιώματα πνευματικής ιδιοκτησίας της DIAGNOSOU.</li>
                    </ul>
                    <p>Επιπλέον, ο χρήστης δεσμεύεται να ενημερώνει τη DIAGNOSOU μόλις αντιληφθεί οποιαδήποτε παραβίαση ή οποιαδήποτε παράνομη ή ακατάλληλη χρήση του Ιστότοπου και/ή όλων των στοιχείων του Ιστότοπου.</p>
                    <p>Ο χρήστης αναγνωρίζει ότι οποιαδήποτε παραβίαση εκ μέρους του των ανωτέρω προκαλεί σημαντική ζημία στη DIAGNOSOU και/ή στο ενδιαφερόμενο τρίτο μέρος, ιδίως όσον αφορά την εικόνα και τη φήμη, και επηρεάζει αναγκαστικά τη DIAGNOSOU σε εμπορικό, οικονομικό και χρηματοοικονομικό επίπεδο, για τα οποία ο χρήστης του Ιστότοπου θα φέρει πλήρη ευθύνη και θα αναλάβει όλες τις συνέπειες.</p>
                </li>
                <li>
                    <h5 class="fw-bold">ΠΡΟΣΤΑΣΙΑ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</h5>
                    <p>Λαμβάνουμε σοβαρά υπόψη την ιδιωτικότητα και την εμπιστευτικότητα των χρηστών μας. Γι' αυτό δεσμευόμαστε να σεβόμαστε την ιδιωτικότητα των προσωπικών δεδομένων, να παραμένουμε διαφανείς και να διατηρούμε τα δεδομένα των Χρηστών ασφαλή. Κάντε κλικ εδώ για περισσότερες πληροφορίες σχετικά με την πολιτική απορρήτου μας.</p>
                </li>
                <li>
                    <h5 class="fw-bold">ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ</h5>
                    <p>Οι Όροι Χρήσης θα διέπονται και θα υπόκεινται αποκλειστικά στους νόμους της Ελλάδας. Οποιαδήποτε διαφορά προκύψει από αυτούς τους Όρους Χρήσης θα επιλύεται μέσω των αρμόδιων δικαστηρίων της Αθήνας, Ελλάδας.</p>
                    <p>Version 1 – Νοέμβριος, 2024</p>
                </li>
            }
            @default {
                <li>
                    <h5 class="fw-bold">Legal Information</h5>
                    <p>The websites <a target="_blank" href="http://diagnosou.com">www.diagnosou.com</a>, <a target="_blank" href="http://diagnosou.gr">www.diagnosou.gr</a> is edited, hosted and is the property of:</p>
                    <p>DIAGNOSOU SINGLE MEMBER P.C<br>
                        Kleisthenous 39-41,<br>
                        15343, Attiki, Greece<br>
                        Greece</p>
                    <p>Registration Number: 179967301000<br>
                        Telephone: [.]<br>
                        Email: <a href="mailto:legal@diagnoseu.com">{{'legal@diagnoseu.com'}}</a></p>
                </li>
                <li>
                    <h5 class="fw-bold">1. Overview</h5>
                    <p>www.diagnosou.com (hereinafter the “Website”) is a commercial website to provide a summary presentation
                        of the activities, products and services offered by DIAGNOSOU SINGLE MEMBER P.C (or DIAGNOSOU), which is
                        headquartered at Kleisthenous 39-4, 15343, Agia Paraskevi, Attiki, Greece and its subsidiaries.</p>
                    <p>The Website is available free of charge to any user with Internet access. All costs related to access to
                        and use of the Website, whether hardware, software or Internet access costs, are the sole responsibility
                        of the user.</p>
                </li>
                <li>
                    <h5 class="fw-bold">2. ACCEPTANCE AND MODIFICATION OF THE TERMS OF USE</h5>
                    <p>Access to and use of the Website shall be subject to the acceptance of and compliance with the terms and
                        conditions of use (“Terms of Use”) set forth herein. By navigating and/or using this Website, the user
                        shall be deemed to have read these Terms of Use, to have fully understood them and to have fully
                        accepted all their provisions without restriction or reservation. If the user does not agree with these
                        Terms of Use, he/she must stop using the Website.</p>
                    <p>DIAGNOSOU reserves the right to change these Terms of Use at any time and without notice. To be informed
                        of any changes, the user must refer before any use of the Website to the latest version of the Terms of
                        Use accessible at any time on the Website. The use of the Website shall be subject to the Terms of Use
                        in effect at the time of such use.</p>
                    <p>Any provision of these Terms of Use prohibited by, or unlawful or unenforceable under any applicable law,
                        enforced by any court of competent jurisdiction shall, to the extent required by such law, be severed
                        from these Terms of Use and rendered ineffective in so far as is possible without modifying the
                        remaining provisions. The invalidity in whole or in part of any provisions of these Terms of Use shall
                        not void or affect the validity of any other provision herein, unless said provision affects the general
                        balance of these Terms of Use.</p>
                </li>
                <li>
                    <h5 class="fw-bold">3. TECHNICAL INFORMATION – AVAILABILITY</h5>
                    <p>The Website is available 24 hours a day, 7 days a week, including Sundays and national holidays, subject
                        to force majeure event or any event beyond the control of DIAGNOSOU and subject to maintenance periods.
                        DIAGNOSOU is only bound by an obligation of means concerning the availability of the Website. DIAGNOSOU
                        reserves the right to interrupt, suspend or limit access to all or part of the Website, in particular
                        due to legal or technical constraints. You expressly acknowledge that the aforementioned maintenance
                        periods and any interruptions, suspensions or limitations may occur at any time without prior
                        notice.</p>
                    <p>You acknowledge, recognize and accept that access to the Website is restricted to the characteristics and
                        limits of the Internet, in particular with regard to technical performance, response times, risks of
                        interruption, and more generally, risks inherent in any connection on the Internet, over which DIAGNOSOU
                        has no control. It is therefore your responsibility to take all appropriate measures to protect your own
                        data and/or software stored on your computer support from any breach.</p>
                    <p>ANY USE OF THE WEBSITE IS AT THE USER’S OWN RISK AND THE USER ACKNOWLEDGES AND AGREES THAT THE WEBSITE IS
                        PROVIDED FOR USE “AS IS” WITHOUT WARRANTY OF ANY KIND. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE
                        LAW, DIAGNOSOU DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER EXPRESS, STATUTORY OR IMPLIED, INCLUDING,
                        WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                        DIAGNOSOU DOES NOT WARRANT THAT THE WEBSITE OR ANY PART THEREOF WILL MEET THE USER’S REQUIREMENTS OR BE
                        UNINTERRUPTED, SECURE OR ERROR-FREE, OR THAT ERRORS WILL BE CORRECTED.</p>
                </li>
                <li>
                    <h5 class="fw-bold">4. HYPERLINKS</h5>
                    <p>Where we provide links to websites of other organizations, these Terms of Use do not cover how you can
                        use the websites of other organizations or how that organization processes personal data. We encourage
                        you to read the terms of use on the other websites you visit. We do not control the websites of other
                        organizations. These websites are controlled by other organizations independent of DIAGNOSOU. DIAGNOSOU
                        disclaims any and all other warranties concerning the websites of other organizations. The user accepts
                        sole and exclusive responsibility for the use of the websites of other organizations.</p>
                </li>
                <li>
                    <h5 class="fw-bold">5. INTELLECTUAL PROPERTY</h5>
                    <p>The Website and/or all elements on the Website (in particular texts, images, signs, logos, photographs,
                        etc.) are protected by intellectual property rights (including in particular all copyrights, patent
                        rights, trademarks, designs, models, databases, domain names and any other existing or future
                        intellectual property rights, Greek and international) and belong to DIAGNOSOU (and/or its licensors as
                        the case may be). The use of the Website shall be not construed as assigning or transferring to the user
                        any or all intellectual property rights relating to the Website and/or all elements on the Website,
                        except a free and non-exclusive personal right of access, limited exclusively to visiting the
                        Website.</p>
                    <p>The names, brands and/or logos “DIAGNOSOU” are the full, entire and exclusive property of DIAGNOSOU
                        SINGLE MEMBER P.C (and/or its licensors as the case may be). Any use, representation, reproduction
                        and/or exploitation, in whole or in part, of these names, trademarks and/or logos, of any nature
                        whatsoever, is totally prohibited.</p>
                    <p>Any use, representation, reproduction and/or exploitation, in whole or in part, of the Website and/or all
                        elements on the Website without the prior written consent of DIAGNOSOU SINGLE MEMBER P.C constitutes a
                        breach of the intellectual property rights of DIAGNOSOU SINGLE MEMBER P.C (and/or its licensors as the
                        case may be).</p>
                    <p>In this respect, and without limitation, the user undertakes not to:</p>
                    <ul class="list-disc">
                        <li>use or attempt to use the Website and/or all elements on the Website, except as provided in these
                            Terms of Use,
                        </li>
                        <li>reproduce, copy, transfer, transmit, summarize, integrate, alter, modify, move, remove, replace or
                            redistribute, permanently or temporarily, on whatever media or in any manner whatsoever, all or part
                            of the Website, its contents, components or constitutive elements, without the prior written
                            authorization from DIAGNOSOU;
                        </li>
                        <li>damage or attempt to damage directly or indirectly the reputation or image of DIAGNOSOU, the Website
                            and generally all the rights held by DIAGNOSOU, in any way whatsoever,
                        </li>
                        <li>block or attempt to block, disrupt or attempt to disrupt the Website,</li>
                        <li>download, distribute or publish in any other way, through the usage of the Website, any content,
                            information or other material that could (i) violate or infringe the rights of any third party; (ii)
                            be libellous, threatening, defamatory, obscene, or indecent or could give rise to any civil or
                            criminal liability under any laws or regulation; (iii) include any bugs, viruses, worms, trap doors,
                            Trojan horses or other harmful code; (iv) contain information that could be used to identify an
                            individual; or (v) violate any applicable laws and regulations;
                        </li>
                        <li>allow, directly or indirectly, by advice, help or assistance, any third party to carry out any of
                            the abovementioned acts or to infringe DIAGNOSOU’ s intellectual property rights.
                        </li>
                    </ul>
                    <p>In addition, the user covenants to inform DIAGNOSOU as soon as it becomes aware of any hacking or any
                        illegal or improper use of the Website and/or all elements on the Website.</p>
                    <p>The user acknowledges that any violation by him of the above causes considerable damage to DIAGNOSOU
                        and/or the third party concerned, in particular in terms of image, reputation, and necessarily impacts
                        DIAGNOSOU in commercial, economic and financial terms for which the user shall be entirely responsible
                        and shall assume all the consequences.</p>
                </li>
                <li>
                    <h5 class="fw-bold">6. PROTECTION OF PERSONAL DATA</h5>
                    <p>We take the privacy and confidentiality of our users seriously. That is why we pledge to respect the
                        privacy of personal data, to remain transparent and to keep your data safe.
                        Click <a [routerLink]="['/gdpr']">here</a> for more
                        information relating to our privacy policy.</p>
                </li>
                <li>
                    <h5 class="fw-bold">7. APPLICABLE LAW</h5>
                    <p>These Terms of Use shall be governed and be exclusively subject to the laws of Greece. Any dispute
                        arising from these Terms of Use shall be settled through the competent courts of Athens, Greece.</p>
                    <p>Version 1 – November, 2024</p>
                </li>
            }
        }

    </ul>
</ng-template>
