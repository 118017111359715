import { Component, Input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MapComponent } from "../map/map.component";
import { RevolabButtonComponent } from '../revolab-button/revolab-button.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-revolab-promo-header',
  standalone: true,
    imports: [NgbModule, MapComponent, RevolabButtonComponent, CommonModule, NgOptimizedImage, TranslateModule],
  templateUrl: './revolab-promo-header.component.html',
  styleUrl: './revolab-promo-header.component.scss'
})
export class RevolabPromoHeaderComponent{
  @Input() device: string = '';
}
