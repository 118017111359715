import {ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {Subscription, timer} from "rxjs";

@Pipe({
    name: "timeAgo",
    pure: true,
    standalone: true
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
    private timer: Subscription | null = null;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone
    ) {
    }

    transform(value: Date | string | number | undefined, lang: string = "el"): string {
        if (!value) return '';

        const date = new Date(value).getTime();
        const now = new Date().getTime();
        const seconds = Math.floor((now - date) / 1000);
        let timeAgo: string;

        const isGreekLang: boolean = lang === "el";
        const justNowLabel = isGreekLang ? 'Μόλις τώρα' : 'Just now';
        const secondsAgoLabel = isGreekLang ? 'δευτερόλεπτα πριν' : 'seconds ago';
        const agoLabel = isGreekLang ? 'πριν' : 'ago';
        const hourLabel = isGreekLang ? 'ώρα' : 'hour';
        const dayLabel = isGreekLang ? 'ημέρα' : 'day';
        const minuteLabel = isGreekLang ? 'λεπτό' : 'minute';
        const minutesLabel = isGreekLang ? 'λεπτά' : 'minutes';
        const hoursLabel = isGreekLang ? 'ώρες' : 'hours';
        const daysLabel = isGreekLang ? 'ημέρες' : 'days';

        if (seconds < 29) { // less than 30 seconds ago
            timeAgo = justNowLabel;
        } else if (seconds < 60) {
            timeAgo = `${seconds} ${secondsAgoLabel}`;
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            timeAgo = `${minutes} ${minutes > 1 ? minutesLabel : minuteLabel} ${agoLabel}`;
        } else if (seconds < 86400) {
            const hours = Math.floor(seconds / 3600);
            timeAgo = `${hours} ${hours > 1 ? hoursLabel : hourLabel} ${agoLabel}`;
        } else {
            const days = Math.floor(seconds / 86400);
            timeAgo = `${days} ${days > 1 ? daysLabel : dayLabel} ${agoLabel}`;
        }


        this._removeTimer();
        const timeToUpdate = this._getSecondsUntilUpdate(seconds) * 1000;

        this.ngZone.runOutsideAngular(() => {
            this.timer = timer(timeToUpdate).subscribe(() => {
                this.ngZone.run(() => this.changeDetectorRef.markForCheck());
            });
        });

        return timeAgo;
    }


    private _removeTimer() {
        if (this.timer) {
            this.timer.unsubscribe();
            this.timer = null;
        }
    }

    private _getSecondsUntilUpdate(seconds: number) {
        const MINUTE = 60;
        const HOUR = 3600;
        const DAY = 86400;

        if (seconds < MINUTE) { // less than 1 minute
            return 1;
        } else if (seconds < HOUR) { // less than an hour
            return 30;
        } else if (seconds < DAY) { // less than a day
            return 300;
        } else {
            return 3600;
        }
    }

    ngOnDestroy(): void {
        this._removeTimer();
    }
}
