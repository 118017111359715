import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList, signal,
    ViewChildren
} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormGroup, FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {RevolabButtonComponent} from "../../core/components/revolab-button/revolab-button.component";
import {NavigationBarComponent} from "../../core/components/navigation-bar/navigation-bar.component";
import {LocalStorageService} from 'ngx-webstorage';
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../core/services/user.service";
import {isEmpty} from "lodash";
import {MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatCheckbox} from "@angular/material/checkbox";
import {CountryService} from "../../core/services/country.service";
import {finalize} from "rxjs";
import {NavigationService} from "../../core/services/navigation.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-mobile-verification',
    standalone: true,
    imports: [NgbModule, CommonModule, ReactiveFormsModule, RevolabButtonComponent, NavigationBarComponent, MatFormField, MatSelect, MatOption, NgOptimizedImage, MatCheckbox, TranslateModule],
    templateUrl: './mobile-verification.component.html',
    styleUrl: './mobile-verification.component.scss'
})
export class MobileVerificationComponent implements OnInit, OnDestroy {

    userId: string = '';
    currentStage!: number;
    labs: any[] = [];
    phoneForm: FormGroup | undefined;
    codeForm!: FormGroup;
    submittedSMSCode: string = '';

    timer: any; // To store the interval reference
    resendTimer: any;
    remainingTime: number = 120; // 2 minutes in seconds
    displayTime: string = '02:00'; // Display format

    resendRemainingTime: number = 25; // 1 minute in seconds
    resendDisplayTime: string = '00:25'; // Display format
    invalidCode: boolean = false;

    countriesLoading = signal(false);
    // Country data with flags and dial codes
    countries: { name: string; dialCode: string; code: string }[] = [];

    @ViewChildren('input') inputs!: QueryList<ElementRef>;


    constructor(
        private router: Router,
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private userService: UserService,
        private countryService: CountryService,
        private ls: LocalStorageService,
        private toastr: ToastrService,
        private navigation: NavigationService
    ) {
        if (this.ls.retrieve('session_token')) {
            this.goBack();
            return;
        }

        this.route.queryParamMap.pipe(takeUntilDestroyed()).subscribe(params => {
            this.currentStage = Number(params.get('currentStage')) || 2;
        });
    }

    ngOnInit(): void {
        this.countriesLoading.set(true);
        this.countryService.phoneCodes()
            .pipe(finalize(() => {
                this.countriesLoading.set(false);
                this.cd.markForCheck();
            }))
            .subscribe((res) => {
                this.countries = res?.countries?.map((country: any) => ({
                    name: country.name,
                    code: country.countryCode,
                    dialCode: country.phonePrefix,
                }));
            })

        // Initialize the form group
        this.phoneForm = this.fb.group({
            countryCode: ['30', Validators.required],
            mobileNumber: ['', [Validators.required, Validators.pattern('[1-9]{1}[0-9]{9}')]]
        });

        this.codeForm = this.fb.group({
            code1: ['', Validators.required],
            code2: ['', Validators.required],
            code3: ['', Validators.required],
            code4: ['', Validators.required],
        });
    }

    ngOnDestroy(): void {
        // Clean up the timer when the component is destroyed
        if (this.timer) {
            clearInterval(this.timer);
            clearInterval(this.resendTimer);
        }
    }


    sendCode(data: any) {
        this.userService.sendCode({
            "send_hash_code": "false",
            "prefix": "+" + data.countryCode,
            "phoneNumber": (data.mobileNumber).toString(),
            "resendProvider": "sms",
            "systemInfo": {
                "deviceType": "web",
                "appVersion": 1
            }
        }).subscribe((response) => {
            this.userId = response.user;
            this.ls.store('uid', this.userId);
        });
    }

    onCurrentStageChange(newStage: number) {
        this.currentStage = newStage; // Update the currentStage based on the navigation bar input
        if (this.timer && this.currentStage === 2) {
            this.remainingTime = 120;
            this.resendRemainingTime = 25;
            clearInterval(this.timer);
            clearInterval(this.resendTimer);
        }
    }

    // Handle form submission
    submitForm() {

        if (this.currentStage === 2) {
            if (this.phoneForm?.valid) {
                const formData = this.phoneForm.value;

                this.currentStage++;

                this.sendCode(formData);

                this.startTimer();
                this.resendTimeStart();

                // Focus the first input field
                setTimeout(() => {
                    this.inputs.first.nativeElement.focus();
                }, 200);

            }
        } else if (this.currentStage === 3) {
            if (this.phoneForm?.valid) {
                const c1 = this.codeForm.value.code1;
                const c2 = this.codeForm.value.code2;
                const c3 = this.codeForm.value.code3;
                const c4 = this.codeForm.value.code4;
                this.submittedSMSCode = c1.toString() + c2.toString() + c3.toString() + c4.toString();

                if (typeof c1 === 'undefined' || typeof c2 === 'undefined' || typeof c3 === 'undefined' || typeof c4 === 'undefined') {
                    this.toastr.info('Please enter the verification code');
                    return;
                }


                this.invalidCode = false;
                this.userService.verifyCode({
                    "code": this.submittedSMSCode,
                    "userId": this.userId || this.ls.retrieve('uid'),
                    "systemInfo": {
                        "deviceType": "web",
                        "appVersion": 1
                    }
                }).subscribe((response) => {
                    if (response.user) {
                        this.ls.store('uid', response.user);
                    }
                    this.ls.store('session_token', response.token);
                    const latestUrl = this.navigation.currentUrl();
                    if (latestUrl && latestUrl !== '/mobile-verification') {
                        this.router.navigate([latestUrl]);
                    } else {
                        this.router.navigate(['/'], {queryParams: {back: true}});
                    }
                });
            }
        }
    }

    goBack() {
        const latestUrl = this.navigation.lastUrl();
        if (latestUrl && latestUrl !== '/mobile-verification') {
            this.router.navigate([latestUrl]);
            return;
        }
        this.router.navigate(['/'], {queryParams: {back: true}});
    }

    onInput(index: number) {
        // Check if the current input has a value and move to the next input
        const val = this.codeForm.get(`code${index + 1}`)?.value;
        if (!isEmpty(val) && !isNaN((Number(val))) && val > -1) {
            if (index < 3) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
            if (index === 3 && this.codeForm.valid) {
                this.submitForm();
            }
        }
    }

    onKeyDown(event: KeyboardEvent, index: number) {
        // Handle backspace to move to the previous input
        if (event.key === 'Backspace' && index > 0 && !this.codeForm.get(`code${index + 1}`)?.value) {
            this.inputs.toArray()[index - 1].nativeElement.focus();
            return;
        }
    }

    validateInput(event: KeyboardEvent) {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
        // Prevent any key that is not a digit or backspace
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    }

    startTimer() {
        this.timer = setInterval(() => {
            if (this.remainingTime > 0) {
                this.remainingTime--;
                this.updateDisplayTime();
                // console.log('Remaining Time:', this.displayTime);
            } else {
                clearInterval(this.timer); // Stop the timer when it reaches 0
            }
        }, 1000);
    }

    resendTimeStart() {
        this.resendTimer = setInterval(() => {
            if (this.resendRemainingTime > 0) {
                this.resendRemainingTime--;
                this.updateResendDisplayTime();
                // console.log('Remaining Time:', this.resendDisplayTime);
            } else {
                clearInterval(this.resendTimer); // Stop the timer when it reaches 0
            }
        }, 1000);
    }

    updateResendDisplayTime() {
        const minutes = Math.floor(this.resendRemainingTime / 60);
        const seconds = this.resendRemainingTime % 60;
        this.resendDisplayTime = `${this.padZero(minutes)}:${this.padZero(seconds)}`;
        this.cd.detectChanges();
    }

    updateDisplayTime() {
        const minutes = Math.floor(this.remainingTime / 60);
        const seconds = this.remainingTime % 60;
        this.displayTime = `${this.padZero(minutes)}:${this.padZero(seconds)}`;
        this.cd.detectChanges();
    }


    padZero(value: number): string {
        return value < 10 ? '0' + value : value.toString();
    }


}
