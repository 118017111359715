import {Component} from '@angular/core';
import {NavigationBarComponent} from "../../core/components/navigation-bar/navigation-bar.component";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {LabService} from "../../core/services/lab.service";
import {Router} from "@angular/router";
import {Exam} from "../../core/interfaces/Exam";
import {ExamService} from "../../core/services/exam.service";
import {TranslateModule} from "@ngx-translate/core";
import {MarkdownPipe} from "../../core/pipes/markdown.pipe";

@Component({
    selector: 'app-test-selection',
    standalone: true,
    imports: [
        CommonModule,
        NavigationBarComponent,
        NgOptimizedImage,
        TranslateModule,
        MarkdownPipe,
    ],
    templateUrl: './test-selection.component.html',
    styleUrl: './test-selection.component.scss'
})
export class TestSelectionComponent {

    availableTests: Exam[] = [];
    selectedTest: Exam | undefined;

    constructor(
        private labService: LabService,
        private router: Router,
        private examService: ExamService
    ) {
        this.examService.retrieve().subscribe((res) => {
            this.availableTests = res.exams?.map((exam: Exam) => {
                if (exam.icon) {
                    return exam;
                }
                if (exam.title.toUpperCase().includes("HPV")) {
                    exam.icon = "assets/images/hpv.svg";
                }
                if (exam.title.toUpperCase().includes("PAP")) {
                    exam.icon = "assets/images/pap.svg";
                }
                return exam;
            });
        });
    }

    applyForTest() {
        if (!this.selectedTest?.id) {
            return;
        }
        this.labService.selectedTests.set([this.selectedTest!]);
        this.router.navigate(['/lab-select']);
    }
}
