import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {LocalStorageService} from "ngx-webstorage";
import {NavigationService} from "../services/navigation.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private localStorage: LocalStorageService,
        private navigationService: NavigationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const token = this.localStorage.retrieve('session_token');
        if (token) {
            return true;
        }

        let attemptedUrl = route.url?.map(segment => segment.path).join('/')
        attemptedUrl = attemptedUrl && !attemptedUrl.startsWith('/') ? '/' + attemptedUrl : attemptedUrl;

        // we do this so mobile-verification can redirect back to the page the user was trying to access
        this.navigationService.updateLastUrl(this.navigationService.currentUrl());
        this.navigationService.updateCurrentUrl(attemptedUrl);
        this.navigationService.skipNextUrlUpdate = true;
        this.router.navigate(['/mobile-verification']);
        return false;

    }
}
