<div class="h-100 d-flex justify-content-between flex-column gap-md-5 gap-3">
    <app-navigation-bar [currentStage]="currentStage"
                        #nav
                        (currentStageChange)="onCurrentStageChange($event)"></app-navigation-bar>
    <div class="container flex-fill overflow-auto">

        @switch (currentStage) {
            @case (4) {
                <div class="text-center position-relative" [ngStyle]="{height: '30svh'}">
                    <img id="idika" ngSrc="/assets/images/Frame.png" class="object-fit-contain"
                         alt="idika" fill priority>
                </div>
                <div class="title mt-5">{{'elig_sms_prompt' | translate}}</div>

            }
            @case (5) {
                <div class="title mb-4">{{ 'elig_criteria_prompt' | translate }}</div>
                <ul class="gap-3 p-0 d-flex flex-column w-fit mx-auto">

                    <li class="d-flex align-items-center gap-3">
                        <i class="fa fa-check-circle fa-2x text-diagnosou-primary"></i>
                        <span>{{'elig_criteria_list_a' | translate}}</span>
                    </li>
                    <li class="d-flex align-items-center gap-3">
                        <i class="fa fa-check-circle fa-2x text-diagnosou-primary"></i>
                        <span>{{'elig_criteria_list_b' | translate}}</span>
                    </li>
                    <li class="d-flex align-items-center gap-3">
                        <i class="fa fa-check-circle fa-2x text-diagnosou-primary"></i>
                        <span>{{'elig_criteria_list_c' | translate}}</span>
                    </li>
                    <li class="d-flex align-items-center gap-3">
                        <i class="fa fa-check-circle fa-2x text-diagnosou-primary"></i>

                        <span>{{'elig_criteria_list_d' | translate}}</span>
                    </li>
                    <li class="d-flex align-items-center gap-3">
                        <i class="fa fa-check-circle fa-2x text-diagnosou-primary"></i>
                        <span>{{'elig_criteria_list_e' | translate}}</span>
                    </li>
                </ul>
            }
            @case (6) {
                <div class="d-flex flex-column gap-5 align-items-center">

                    <div class="cost-text">
                        {{'elig_cover_title' | translate}}
                    </div>
                    <div class="text-center w-100 position-relative" [ngStyle]="{height: '30svh'}">
                        <img id="cost" ngSrc="/assets/images/Frame-2.png" alt="" fill class="object-fit-contain">
                    </div>
                    <div class="title">
                        {{'elig_cover_subtitle' | translate}}
                    </div>
                </div>
            }
            @case (7) {
                <div class="d-flex flex-column align-items-center gap-3" [formGroup]="amkaForm">

                    @if (loading()) {
                        <app-loader [loading]="true"></app-loader>
                    } @else if (labService.selectedLab() && labService.selectedTests()?.length) {
                        <p class="title">
                            {{ 'amka_entry_prompt' | translate }}
                        </p>

                        <input
                            type="text"
                            inputmode="numeric"
                            id="amka"
                            name="amka"
                            maxlength="11"
                            formControlName="amka"
                            class="form-control amka-input"
                            [ngClass]="{'is-invalid': amkaForm.get('amka')?.invalid && amkaForm.get('amka')?.touched}"
                            (keydown)="allowOnlyNumbers($event)"
                            placeholder="{{'social_security_placeholder'|translate}}"
                        />

                        <!-- Terms and Conditions Checkbox -->
                        <app-terms-checkbox formControlName="terms" redirectQuery="currentStage=7"></app-terms-checkbox>
                    } @else {
                        <div class="alert alert-info d-flex align-items-center alert-purple gap-2">
                            <i class="fa fa-exclamation-circle alert-heading"></i>
                            <p class="m-0">Φαίνεται ότι δεν έχετε
                                επιλέξει {{ labService.selectedTests()?.length ? 'εξεταστικό κέντρο' : 'τεστ' }}.
                                Παρακαλούμε
                                <a href="/{{ labService.selectedTests()?.length ? 'lab' : 'test' }}-select" class="text-diagnosou-primary fw-bold mx-1">
                                    επιλέξτε {{ labService.selectedTests()?.length ? 'εξεταστικό κέντρο' : 'τεστ' }}
                                </a>
                                για να συνεχίσετε
                            </p>
                        </div>
                    }
                </div>
            }
        }
    </div>


    <div class="d-flex flex-column gap-3 align-items-center flex-md-row-reverse w-100 px-3 pb-3 px-md-5 pb-md-5">
        @if ([4, 5, 6].includes(currentStage)) {
            <div class="d-flex justify-content-center align-items-center gap-2 btn-container">
                <button class="btn btn-dark border-0 fw-bold fs-6 flex-fill"
                        (click)="buttonClick('no' + (currentStage === 5 ? '-i-dont' : ''))">
                    {{ (currentStage !== 5 ? 'elig_cover_negative_button' : 'elig_criteria_negative_button') | translate }}
                </button>
                <button class="btn btn-gradient flex-fill" (click)="buttonClick('yes')">
                    {{ (currentStage !== 5 ? 'elig_cover_positive_button' : 'elig_criteria_positive_button') | translate }}
                </button>
            </div>

            <button [hidden]="currentStage === 6"
                    class="btn fw-bold text-decoration-underline fs-6"
                    (click)="buttonClick('not-sure')">
                {{'elig_criteria_unaware_button' | translate}}
            </button>
        }
        @if (currentStage === 7) {
            <div class="d-flex justify-content-end w-100">
                <button [disabled]="amkaForm.invalid || loading()" (click)="submit()"
                        class="btn flex-grow-1 flex-md-grow-0 btn-gradient">
                    {{ 'social_security_validation_button' | translate }}
                </button>
            </div>
        }

        <div class="d-none flex-fill d-md-block">
            <button class="btn btn-secondary back-btn fs-6 fw-bold w-auto d-md-block d-none"
                    [disabled]="loading()"
                    (click)="nav.goBack()">
                {{'generic_back_btn' | translate}}
            </button>
        </div>
    </div>
</div>
