import {Pipe, PipeTransform} from "@angular/core";
import {Converter} from "showdown";

@Pipe({
    name: "markdown",
    pure: true,
    standalone: true
})
export class MarkdownPipe implements PipeTransform {
    private converter = new Converter({
        extensions: [
            {
                type: "output",
                regex: /<p>/g,
                replace: "<p class='m-0'>"
            }
        ]
    });

    transform(value?: string): string {
        if (!value) {
            return '';
        }

        return this.converter.makeHtml(value);
    }
}
