import {Component, input, output} from '@angular/core';
import {Appointment} from "../../../../core/interfaces/Appointment";
import {MatCardModule} from "@angular/material/card";
import {CommonModule} from "@angular/common";
import {TimeAgoPipe} from "../../../../core/pipes/timeAgo.pipe";
import {TranslateModule} from "@ngx-translate/core";
import {preferred_lang} from "../../../../core/signals/translation.signal";

@Component({
  selector: 'app-appointment-card',
  standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        TimeAgoPipe,
        TranslateModule,
    ],
  templateUrl: './appointment-card.component.html',
  styleUrl: './appointment-card.component.scss'
})
export class AppointmentCardComponent {

    appointment = input<Appointment | undefined>(undefined);
    cancel = output();
    reschedule = output();

    protected readonly preferred_lang = preferred_lang;
}
