@if (activeAction) {
    <div @fade class="pending-action-container container pt-3 h-100 d-flex flex-column align-items-center justify-content-center flex-fill gap-5">
        <div class="text-center">
            <i [hidden]="activeAction.type !== 'ssnReview'"
               class="text-diagnosou-accent fa fa-check-circle fa-6x mb-3">
            </i>
            <h3 class="fw-bold">{{ activeAction.title }}</h3>
            <small>{{ activeAction.subtitle ?? '' }}</small>
        </div>
        <div class="w-100"
             [hidden]="activeAction.type !== 'reschedule'"
             [ngStyle]="{height: '128px'}">
            <img ngSrc="/assets/images/existing-appointment.svg" fill
                 alt="reschedule appointment" class="object-fit-contain">
        </div>
        @switch (activeAction.type) {
            @case ('reschedule') {
                @if (activeAction.appointment.lab) {
                    <div class="flex-fill d-flex align-items-baseline">
                        <div class="d-flex gap-3 align-items-center rounded-2 border py-3 px-md-5 px-2">
                            @if (activeAction.appointment.lab.logo) {
                                <p class="m-0">
                                    <img src="{{activeAction.appointment.lab.logo}}" height="64" width="64"
                                         alt="lab logo"/>
                                </p>
                            }
                            <div>
                                <span>
                                    @if (activeAction.appointment.lab.displayName) {
                                        <b>{{ activeAction.appointment.lab.displayName }}</b><br>
                                    }
                                    @if (activeAction.appointment.lab.address) {
                                        {{ activeAction.appointment.lab.address }}<br>
                                    }
                                </span>
                                <span class="d-block fs-6 mt-2">
                                    @if (activeAction.appointment.date) {
                                        <b>
                                            {{ activeAction.appointment.date|date:'EEEE, dd MMMM':undefined:preferred_lang() }}
                                            ,
                                            {{ activeAction.appointment.slot }}
                                        </b><br>
                                    }
                                    @if (activeAction.appointment.bookingNo) {
                                        #{{ activeAction.appointment.bookingNo }}
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                }
            }
            @case ('ssnReview') {
                @if (activeAction.ssnRequest.lab) {
                    <div class="flex-fill d-flex align-items-baseline">
                        <div class="d-flex gap-3 rounded-2 align-items-center border py-3 px-2 px-md-5">
                            @if (activeAction.ssnRequest.lab.logo) {
                                <p class="m-0">
                                    <img src="{{activeAction.ssnRequest.lab.logo}}" height="48" width="48"
                                         alt="lab logo"/>
                                </p>
                            }
                            <span>
                                @if (activeAction.ssnRequest.lab.displayName) {
                                    <b>{{ activeAction.ssnRequest.lab.displayName }}</b><br>
                                }
                                @if (activeAction.ssnRequest.lab.address) {
                                    {{ activeAction.ssnRequest.lab.address }}<br>
                                }
                            </span>
                        </div>
                    </div>
                }
            }
        }

        <div class="sticky-bottom bg-white py-3 w-100 d-grid gap-2">
            <a [routerLink]="[activeAction.identifier]" class="btn fw-bold btn-gradient btn-block">
                @switch (activeAction.type) {
                    @case ('reschedule') {
                        {{ 'reschedule_popup_button_title'|translate }}
                    }
                    @case ('ssnReview') {
                        {{ 'ssn_review_popup_button_title'|translate }}
                    }
                }
            </a>
            @if (activeAction.canCancel) {
                <a class="btn btn-outline-danger"
                   [routerLink]="['/appointments']"
                   [queryParams]="{cancelHash: activeAction.identifier.split('/').pop()}">
                    {{ 'reschedule_popup_cancel_button'|translate }}
                </a>
            }
            @if (activeAction.canSkip) {
                <button class="btn btn-link text-dark skip-btn" (click)="skipCurrentAction()">
                    {{ 'reschedule_popup_skip_button'|translate }}
                </button>
            }
        </div>
    </div>
}
