import {AfterViewInit, Component, ElementRef, OnInit, signal, ViewChild} from '@angular/core';
import {LabService} from "../../core/services/lab.service";
import {finalize} from "rxjs";
import {Appointment, AppointmentStatus} from "../../core/interfaces/Appointment";
import {LoaderComponent} from "../../core/components/loader/loader.component";
import {AppointmentComponent} from "../appointment/appointment.component";
import {AppointmentCardComponent} from "./components/appointment-card/appointment-card.component";
import Swal from "sweetalert2";
import {ActivatedRoute, Router} from "@angular/router";
import {BackendService} from "../../core/services/backend.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {LocalStorageService} from "ngx-webstorage";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ModalComponent} from "../../core/components/modal/modal.component";
import {MatRadioModule} from "@angular/material/radio";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'app-appointments',
    standalone: true,
    imports: [
        LoaderComponent,
        AppointmentComponent,
        AppointmentCardComponent,
        TranslateModule,
        ModalComponent,
        MatRadioModule,
        CommonModule,
        FormsModule
    ],
    templateUrl: './appointments.component.html',
    styleUrl: './appointments.component.scss'
})
export class AppointmentsComponent implements OnInit, AfterViewInit {

    loading = signal(false);
    page = signal(0);
    limit = signal(20);
    hasNextPage = false;

    public appointments: Appointment[] = [];
    public currentAppointment: Partial<Appointment> | undefined = undefined;

    public cancellationReasons: any[] = [];
    public rescheduleReasons: any[] = [];

    public selectedReason: {id: string; title: string; hasText?: boolean} | null = null;
    public userComments: string = '';

    private intersectionObserver: IntersectionObserver | undefined;

    @ViewChild("cancellationModal") cancellationModal: ModalComponent|undefined;
    @ViewChild("rescheduleModal") rescheduleModal: ModalComponent|undefined;
    @ViewChild("loadMore") loadMore: ElementRef|undefined;

    constructor(
        private labService: LabService,
        private backendService: BackendService,
        private localStorage: LocalStorageService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.fetchReasons();
        this.localStorage.observe("preferred_lang").pipe(takeUntilDestroyed()).subscribe(() => {
            this.fetchReasons();
        });
    }

    ngOnInit() {

        if (this.route.snapshot.queryParams['view'] === "active") {
            this.fetchActiveAppointment();
        } else {
            this.fetchAppointments(true);
        }

        if (this.route.snapshot.queryParams['cancelHash']) {
            this.cancelAppointmentByHash(this.route.snapshot.queryParams['cancelHash']);
        }

        this.router.navigate([], {queryParams: {view: null, cancelHash: null}, queryParamsHandling: 'merge'});
    }

    ngAfterViewInit() {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && this.hasNextPage) {
                    this.fetchAppointments();
                }
            });
        }, {
            root: null, // observe relative to viewport
            threshold: 0
        });

        this.intersectionObserver.observe(this.loadMore?.nativeElement);
    }

    public fetchAppointments(fresh?: boolean) {
        if (fresh) {
            this.page.set(0);
            this.hasNextPage = false;
            this.appointments = [];
        } else {
            this.page.update(p => p + 1);
        }
        this.loading.set(true);
        this.labService.retrieveCurrentUserAppointments(this.page(), this.limit())
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe(res => {
                if (!res.success) {
                    return;
                }
                this.hasNextPage = res['appointments'].length === this.limit();
                this.appointments.push(...res['appointments']);
            });
    }

    public fetchActiveAppointment() {
        this.loading.set(true);
        this.hasNextPage = false;
        this.labService.retrieveActiveAppointment(this.labService.selectedTests()?.map(t => t.id) ?? [])
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe(res => {
                if (!(res.success && res.appointment?.id)) {
                    return;
                }
                this.appointments = [res.appointment];
            });
    }

    public cancelAppointmentByHash(hash: string) {
        this.loading.set(true);
        this.hasNextPage = false;
        this.labService.retrieveAppointmentByHash(hash)
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe(res => {
                if (!(res.success && res.appointmentId)) {
                    return;
                }
                this.requestCancellation({id: res.appointmentId})
            });
    }

    public requestCancellation(appointment: Partial<Appointment>) {
        this.currentAppointment = appointment;
        this.cancellationModal?.show()
    }

    public requestReschedule(appointment: Appointment) {
        if (appointment.status === AppointmentStatus.RESCHEDULE) {
            this.rescheduleAppointment(appointment);
            return;
        }
        this.currentAppointment = appointment;
        this.rescheduleModal?.show();
    }

    public async rescheduleAppointment(appointment: Partial<Appointment>) {
        if (!(appointment?.id && appointment.lab?.id && (this.selectedReason?.id || appointment.status === AppointmentStatus.RESCHEDULE))) {
            return;
        }

        this.labService.selectedLab.set(appointment.lab && {
            id: appointment.lab.id!,
            logo: appointment.lab.logo,
            name: appointment.lab.displayName,
            rating: appointment.lab.ratings?.stars,
            ratingCount: appointment.lab.ratings?.count,
            address: appointment.lab.address,
        });
        this.labService.rescheduleId.set(appointment.id);
        this.labService.selectedTests.set(appointment.exams!.map(e => ({id: e.id!, title: e.title})));

        this.labService.rescheduleReasonId.set(this.selectedReason?.id ?? null);
        this.labService.rescheduleReasonComment.set(this.selectedReason ? this.userComments : null);
        this.router.navigate(['/appointment']);
    }

    public async cancelAppointment(appointment: Partial<Appointment>) {
        if (!(appointment?.id && this.selectedReason?.id)) {
            return;
        }

        this.loading.set(true);
        this.labService.cancelAppointment(appointment.id, this.selectedReason.id, this.userComments)
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe(res => {
                if (!res.success) {
                    return;
                }

                Swal.fire({
                    title: this.translate.instant('appointment_cancel_success_message'),
                    icon: 'success',
                    timer: 2000,
                });
                this.fetchAppointments(true);
                this.cancellationModal?.hide();
                // this.appointments = this.appointments.filter(a => a.id !== appointment.id);
            });
    }

    public resetReason() {
        this.selectedReason = null;
        this.userComments = '';
    }

    private fetchReasons() {
        this.backendService.getCancellationReasons()
            .subscribe(res => {
                if (!res.success) {
                    return;
                }
                this.cancellationReasons = res['reasons'];
            })
        this.backendService.getRescheduleReasons()
            .subscribe(res => {
                if (!res.success) {
                    return;
                }
                this.rescheduleReasons = res['reasons'];
            })
    }
}
