import {Component, Input} from '@angular/core';
import {LocalStorageService} from "ngx-webstorage";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CommonModule} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {Router, RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {Language} from "../../interfaces/Generic";

@Component({
    selector: 'app-account-menu',
    standalone: true,
    imports: [CommonModule, MatMenuModule, RouterLink, TranslateModule],
    templateUrl: './account-menu.component.html',
    styleUrl: './account-menu.component.scss'
})
export class AccountMenuComponent {

    @Input() dark: boolean = false;

    authenticated: boolean = false;
    lang: Language|undefined;

    // Maybe get these from the API?
    languages: Language[] = [
        { name: "English", code: "gb", locale: 'en' },
        { name: "Greek", code: "gr", locale: 'el' }
    ]

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router
    ) {
        this.authenticated = !!this.localStorageService.retrieve("session_token");
        this.lang = this.languages
            .find(l => l.locale === (this.localStorageService.retrieve("preferred_lang") ?? 'el'));
        this.localStorageService.observe("session_token")
            .pipe(takeUntilDestroyed())
            .subscribe((token: string) => {
                this.authenticated = !!token;
            });
    }

    logout() {
        this.localStorageService.clear("session_token");
        this.router.navigate(["/"], {queryParams: {back: true}});
    }

    changeLang(lang: Language) {
        this.localStorageService.store("preferred_lang", lang.locale);
        this.lang = lang;
    }
}
