import {Component, output, signal} from '@angular/core';
import {finalize, firstValueFrom} from "rxjs";
import {BackendService} from "../../core/services/backend.service";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {preferred_lang} from "../../core/signals/translation.signal";
import {RouterLink} from "@angular/router";
import {LocalStorageService} from "ngx-webstorage";
import {PendingAction} from "../../core/interfaces/PendingAction";
import {fade} from "../../core/animations/enter-leave.animation";

@Component({
    selector: 'app-pending-actions',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        TranslateModule,
        RouterLink
    ],
    animations: [fade],
    templateUrl: './pending-actions.component.html',
    styleUrl: './pending-actions.component.scss'
})
export class PendingActionsComponent {
    protected readonly preferred_lang = preferred_lang;

    public hasPendingActions = output<boolean>();
    public loading = signal(false);

    public actions: PendingAction[] = [];
    public activeAction: PendingAction | undefined;

    constructor(
        private backendService: BackendService,
        private localStorage: LocalStorageService
    ) {
        if (!this.localStorage.retrieve("session_token")) {
            return;
        }
        this.loading.set(true);
        this.backendService.retrievePendingActions()
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe((res) => {
                this.hasPendingActions.emit(res["actions"]?.length > 0);
                this.actions = res["actions"] ?? [];
                this.activeAction = this.actions?.shift();
            })
    }

    skipCurrentAction() {
        if (this.activeAction?.identifier) {
            firstValueFrom(this.backendService.skipPendingAction(this.activeAction.identifier));
        }
        this.activeAction = undefined;
        const action = this.actions?.shift();
        if (!action) {
            this.hasPendingActions.emit(false);
            return;
        }

        // Wait for the animation to finish
        setTimeout(() => {
            this.activeAction = action;
        }, 200);
    }
}
