import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpServiceBase} from "../classes/HttpServiceBase";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class CountryService extends HttpServiceBase {
    protected override baseUrl: string = "/countries";


    constructor(
        private http: HttpClient,
    ) {
        super();
        this.assign(this);
    }

    public phoneCodes(): Observable<any> {
        return this._http.post(`${this.baseUrl}/phone-codes`, {})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

}
