<div class="h-100 d-flex flex-column">

    <app-navigation-bar [currentStage]="currentStage" #nav
                        (currentStageChange)="onCurrentStageChange($event)"></app-navigation-bar>
    <div class="container flex-fill d-flex flex-column gap-2">
        <div class="row">
            <div class="col-12" style="height: 400px;margin-top: 7rem;">
                @switch (currentStage) {
                    @case (2) {
                        <p class="title">{{ 'textfield_phone_number_title' | translate }}</p>
                        <form class="d-flex justify-content-center align-items-center gap-2" [formGroup]="phoneForm!"
                              (ngSubmit)="submitForm()">
                            <!-- Country Code Dropdown -->
                            <mat-select [panelWidth]="200"
                                        class="form-control code-input"
                                        formControlName="countryCode">
                                @for (country of countries; track (country.dialCode + country.name)) {
                                    <mat-option [value]="country.dialCode" class="d-flex align-items-center gap-1">
                                        <span class="fi fi-{{country.code.toLowerCase()}}"></span>
                                        {{ country.dialCode.startsWith('+') ? '' : '+' }}{{ country.dialCode }}
                                    </mat-option>
                                }
                            </mat-select>

                            <!-- Mobile Number Input -->
                            <div class="form-group">
                                <input
                                    type="tel"
                                    id="mobileNumber"
                                    formControlName="mobileNumber"
                                    class="form-control number-input"
                                    [ngClass]="{'is-invalid': phoneForm!.get('mobileNumber')?.invalid && phoneForm!.get('mobileNumber')?.touched}"
                                    placeholder="{{'generic_textfield_placeholder_phone' | translate}}"
                                />
                            </div>
                        </form>
                    }
                    @case (3) {
                        <div [class.d-block]="remainingTime === 0"
                             [class.d-none]="remainingTime !== 0"
                             class="expire-text my-2">
                            {{ 'login_otp_error_expired' | translate }}
                        </div>
                        <div [class.d-none]="!invalidCode"
                             class="expire-text my-2">
                            {{ 'login_otp_error_wrong' | translate }}
                        </div>
                        <div class="title text-center mb-4">{{
                                'login_otp_prompt' | translate
                            }}
                        </div>
                        <div [ngStyle]="{'color': remainingTime===0 ? '#C6C6C6' : '#19A0A8'}" class="timer mb-4">
                            {{ 'login_otp_expiration_left' | translate }}
                            <span [class.text-diagnosou-primary]="!!remainingTime"
                                  [class.text-danger]="!remainingTime"
                                  class="ms-1">
                                {{ displayTime }}
                            </span></div>
                        <form class="d-flex justify-content-center align-items-center" [formGroup]="codeForm"
                              (ngSubmit)="submitForm()">
                            <div class="form-group d-flex justify-content-center align-items-center gap-3">
                                @for (inp of [].constructor(4); let i = $index; let f = $first; track i) {
                                    <input
                                        type="text" inputmode="numeric"
                                        id="{{'code' + (i+1)}}"
                                        formControlName="{{'code' + (i+1)}}"
                                        class="form-control code-box"
                                        [class.is-invalid]="codeForm.get('code' + (i+1))?.invalid && codeForm.get('code' + (i+1))?.touched"
                                        (input)="onInput(i)"
                                        (keydown)="validateInput($event); onKeyDown($event, i)"
                                        #input
                                        [autofocus]="f"
                                    />
                                }
                            </div>
                        </form>

                        <div class="d-flex justify-content-center align-items-center gap-2 mt-4">
                            <div class="resend">Δεν λάβατε τον κωδικό;</div>
                            <div class="btn resend-btn d-flex align-items-center"
                                 [ngStyle]="{'background': resendRemainingTime===0 ? '#19A0A8' : '#C6C6C6'}"
                                 (click)="sendCode(phoneForm?.value)">
                                {{ 'login_otp_resend_button' | translate }}
                                <span *ngIf="resendRemainingTime!==0" class="ms-1">{{ resendDisplayTime }}</span>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
    </div>

    <div class="m-md-5 m-3 d-flex justify-content-between">
        <button class="btn btn-secondary fs-6 fw-bold d-md-block d-none"
                (click)="goBack()">
            {{ 'generic_back_btn'|translate }}
        </button>
        <button [disabled]="phoneForm?.invalid"
                class="btn btn-gradient flex-fill flex-md-grow-0"
                (click)="submitForm()">
            {{ 'generic_button_next' | translate }}
        </button>
    </div>
</div>





