import {ElementRef, Injectable, signal} from '@angular/core';
import {NavigationBarComponent} from "../components/navigation-bar/navigation-bar.component";
import {environment} from "../../../environments/environment";
import * as Sentry from "@sentry/angular";
import {LocalStorageService} from "ngx-webstorage";

@Injectable({
    providedIn: 'root'
})
export class AppLocalStateService {

    public mainElement = signal<ElementRef|undefined>(undefined);
    public navBar = signal<NavigationBarComponent|undefined>(undefined);
    public navBarHidden = signal<boolean>(false);

    constructor(private localStorageService: LocalStorageService) {
    }

    setupSentryUser() {
        if (environment.production) {
            try {
                Sentry.setContext("diagnosou_user", {
                    id: this.localStorageService.retrieve('uid'),
                    sessionToken: this.localStorageService.retrieve('session_token'),
                    installation: this.localStorageService.retrieve('installationId'),
                });
            } catch (e) {
                console.error(e, 'Error setting up sentry user');
            }
        }
    }
}
