<app-loader [loading]="loading()"></app-loader>
<div class="h-100 flex-column justify-content-between d-flex p-md-5 p-3">
    <div class="container">
        <div class="row">
            <div class="col col-md-6 offset-md-3">
                <div class="alert alert-purple" [innerHTML]="'contact_us_screen_prompt'|translate|markdown">

                </div>
                @if (!authenticated) {
                    <label for="phoneNumber" class="fs-5 mb-2">{{ 'generic_textfield_placeholder_phone'|translate }}</label>
                    <input id="phoneNumber"
                           name="phoneNumber"
                           [(ngModel)]="phoneNumber"
                           #input
                           (ngModelChange)="sanitizePhone(input)"
                           type="tel"
                           class="form-control bg-light border-0 rounded-3 mb-3"
                           placeholder="{{'optional'|translate}}"/>
                }
            </div>
        </div>
        <div class="row">
            <div class="col col-md-6 offset-md-3">
                <label for="comments" class="fs-5 mb-2">{{ 'contact_us_input_title'|translate }}</label>
                <textarea rows="5"
                          id="comments"
                          name="comments"
                          [(ngModel)]="comments"
                          class="form-control bg-light border-0 rounded-3"
                          placeholder="{{'contact_us_input_placeholder'|translate}}"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center justify-content-md-between d-md-flex d-grid">
            <button [routerLink]="[navigation.lastUrl() ?? '/']"
                    class="btn btn-secondary fw-bold d-none d-md-block">
                {{ 'generic_back_btn'|translate }}
            </button>
            <button [disabled]="!comments"
                    (click)="send()"
                    class="btn btn-gradient">
                {{ 'contact_us_send_button'|translate }}
            </button>
        </div>
    </div>
</div>
